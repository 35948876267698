import Layout from '../../components/Layout';
import OrderItems from '../orders/order_items';
import React, { useState, useEffect } from 'react';
import {
  Button,
  Form,
  Alert,
  Col,
  Row,
  Modal,
  Table,
  Jumbotron,
} from 'react-bootstrap';
import Select from 'react-select';
import OrderService from '../../api/order.service';
import ProductService from '../../api/product.service';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { SHIPPING_TYPE } from '../../constants/shipping_type';
import { US_STATES } from '../../constants/us_states';
import { CATEGORIES } from '../../constants/listing_categories';
import { addItem, setItems } from '../../store/orderItems.slice';
import { useDispatch, useSelector } from 'react-redux';
import { GiFiles } from 'react-icons/gi';
import UploadModal from '../../components/UploadModal';
import { useHistory, Link } from 'react-router-dom';
import amz from '../../img/amazon.png';
import '../../style/edit_order.css';

const EditOrders = () => {
  const dispatch = useDispatch();
  let { id } = useParams();
  const history = useHistory();

  const { line_items } = useSelector((state) => state.orderItems);

  const [errors, setErrors] = useState(null);
  const [order, setOrder] = useState(null);
  const [shipping, setShipping] = useState(null);
  const [products, setProducts] = useState(null);
  const [shippings, setShippings] = useState(null);
  const [product, setProduct] = useState(null);
  const [countries, setCountries] = useState(null);
  const [country, setCountry] = useState(null);
  const [states, setStates] = useState(null);
  const [state, setState] = useState(null);
  const [categories, setCategories] = useState(null);

  //PackSlip
  const [showPackSlip, setShowPackSlip] = useState(false);
  const [showShip, setShowShip] = useState(false);
  const [show, setShow] = useState(false);
  const [showCustomerFiles, setShowCustomerFiles] = useState(false);

  const handleClosePackSlip = () => setShowPackSlip(false);
  const handleShowPackSlip = () => setShowPackSlip(true);
  const handleCloseShip = () => setShowShip(false);
  const handleShowShip = () => setShowShip(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseCustomerFiles = () => setShowCustomerFiles(false);
  const handleShowCustomerFiles = () => setShowCustomerFiles(true);

  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption.value);
  };

  const handleProductChange = (selectedOption) => {
    setProduct(JSON.parse(selectedOption.value));
  };

  const handleShippingChange = (selectedOption) => {
    setShipping(selectedOption.value);
  };

  const handleStateChange = (selectedOption) => {
    setState(selectedOption.value);
  };

  const handleCategoryChange = (selectedOption) => {
    // by_category
    ProductService.by_category(selectedOption.value).then((products) => {
      setProducts(
        products.map((product, index) => ({
          label: `${product.name} - ${product.color} - ${product.size} - ${product.source}`,
          value: `${JSON.stringify(product)}`,
        }))
      );
    });
  };

  useEffect(() => {
    ProductService.all().then((products) => {
      setProducts(
        products.map((product, index) => ({
          label: `${product.name} - ${product.color} - ${product.size} - ${product.source}`,
          value: `${JSON.stringify(product)}`,
        }))
      );
    });
    setCountries([{ label: 'United States Of America', value: 'USA' }]);
    setShippings(
      SHIPPING_TYPE.map((method, index) => ({
        label: method,
        value: method,
      }))
    );
    setStates(
      US_STATES.map((states, index) => ({
        label: states.name,
        value: states.code,
      }))
    );
    setCategories(
      CATEGORIES.map((category, index) => ({
        label: category,
        value: category,
      }))
    );
    OrderService.by_id(id).then((order) => {
      setOrder(order);
      setShipping(order.shipping_method);
      dispatch(setItems(order.ordered_items));
      setState(
        order.customer_address
          ? order.customer_address.state
            ? order.customer_address.state
            : ''
          : ''
      );
      setCountry(order.customer_address ? order.customer_address.country : '');
      if (
        order.customer_address === undefined ||
        order.customer_address === null ||
        order.customer_address === undefined
      ) {
        order.customer_address = {
          street: '',
          city: '',
          state: '',
          country: '',
          postal_code: '',
        };
      }
      if (!order.first_name && !order.last_name) {
        order.first_name = order.customer_name.split(' ')[0];
        order.last_name = order.customer_name.split(' ')[1];
      }
    });
  }, [id, dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const order = {
        number: event.target.number.value,
        system_number: event.target.system_number.value,
        order_date: event.target.order_date.value,
        ship_date: event.target.ship_date.value,
        customer_paid_for_shipping: Boolean(
          event.target.customer_paid_for_shipping.checked
        ),
        first_name: event.target.first_name.value,
        last_name: event.target.last_name.value,
        customer_email: event.target.customer_email.value,
        ordered_items: line_items,
        introduction_email: Boolean(event.target.introduction_email.checked),
        proof_sent: Boolean(event.target.proof_sent.checked),
        proof_approved: Boolean(event.target.proof_approved.checked),
        artwork_uploaded: Boolean(event.target.artwork_uploaded.checked),
        apparel_ordered: Boolean(event.target.apparel_ordered.checked),
        id: id,
        notes: event.target.notes.value,
        shipping_method: shipping,
        shipping_day_message: Boolean(
          event.target.shipping_day_message.checked
        ),
        uploaded_proof: Boolean(event.target.uploaded_proof.checked),
        entered_in_system: Boolean(event.target.entered_in_system.checked),
        follow_up_message: Boolean(event.target.follow_up_message.checked),
        customer_address: {
          street: event.target.street.value,
          city: event.target.city.value,
          state: state,
          country: country,
          postal_code: event.target.postal_code.value,
        },
      };
      await OrderService.update(order).then(() => {
        toast.success('Order updated successfully!', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        OrderService.by_id(id).then((order) => {
          setOrder(order);
          setShipping(order.shipping_method);
          dispatch(setItems(order.ordered_items));
        });
      });
    } catch (e) {
      console.error(e);
      setErrors([
        e.response.data === undefined ? e.response : e.response.data.message,
      ]);
    }
  };

  // const cancel = async () => {
  //     await OrderService.cancel({ id: order._id }).then(() => {
  //         toast.warning('Order canceled successfully!', {
  //             position: "top-center",
  //             autoClose: 2000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: false,
  //             draggable: true,
  //             progress: undefined,
  //         });
  //         OrderService.by_id(order._id).then((order) => {
  //             setOrder(order);
  //             setShipping(order.shipping_method);
  //             dispatch(setItems(order.ordered_items));
  //         });
  //     });
  // };

  // const complete = async () => {
  //     await OrderService.complete({ id: order._id }).then(() => {
  //         toast.success('Order completed successfully!', {
  //             position: "top-center",
  //             autoClose: 2000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: false,
  //             draggable: true,
  //             progress: undefined,
  //         });
  //         OrderService.by_id(order._id).then((order) => {
  //             setOrder(order);
  //             setShipping(order.shipping_method);
  //             dispatch(setItems(order.ordered_items));
  //         });
  //     });
  // };

  // const del = async () => {
  //     await OrderService.delete({ id: order._id }).then(() => {
  //         toast.danger('Order deleted successfully!', {
  //             position: "top-center",
  //             autoClose: 2000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: false,
  //             draggable: true,
  //             progress: undefined,
  //         });
  //         history.push('/orders');
  //     });
  // };

  const addOrderItem = (event) => {
    event.preventDefault();
    var qty = document.getElementById('customer_qty_ordered').value;
    if (qty < 1) {
      toast.error(`Quantity can't be lower than 1.`, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    var order_item = {
      product_name: product.name,
      product_code: product.code,
      product_size: product.size,
      product_color: product.color,
      customer_qty_ordered: qty,
      source: product.source,
    };
    dispatch(addItem(order_item));
    document.getElementById('customer_qty_ordered').value = 1;
  };

  const uploadFile = async (event) => {
    event.preventDefault();

    const type = event.target.id;

    if (event.target[0].files.length <= 0) {
      toast.error('No file selected.', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    toast.warning('Please wait while we upload your file...', {
      position: 'top-center',
    });

    await OrderService.upload({
      order_id: id,
      file: event.target[0].files[0],
      type: type,
    })
      .then(() => {
        toast.dismiss();
        setShow(false);
        setShowCustomerFiles(false);
        toast.success('File uploaded successfully!', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        OrderService.by_id(id).then((order) => {
          setOrder(order);
          setShipping(order.shipping_method);
          dispatch(setItems(order.ordered_items));
          window.location.reload();
        });
      })
      .catch((e) => {
        toast.error(
          e.response.data === undefined
            ? e.response
            : errors
            ? errors
            : e.response.data.message,
          {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          }
        );
      });
  };

  const removeCustomerFile = async (url) => {
    await OrderService.remove_upload({
      order_id: id,
      url: url,
      type: 'customer_file',
    })
      .then(() => {
        toast.success('Deleted successfully!', {
          position: 'top-center',
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((e) => {
        toast.error(
          e.response.data === undefined ? e.response : e.response.data.message,
          {
            position: 'top-center',
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          }
        );
      });
  };

  return (
    <Layout>
      {order && (
        <div className="row">
          <div className="col-md-12">
            <Button
              as={Link}
              onClick={(event) => history.push('/orders')}
              className="btn btn-info mb-3"
            >
              Back
            </Button>

            {order.sales_channel && order.sales_channel !== '' && (
              <img
                src={amz}
                alt="test"
                style={{ width: 'auto', height: '40px', float: 'right' }}
              />
            )}
          </div>

          <div className="col-md-12">
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md="12">
                  <Jumbotron>
                    <Row>
                      <Col md="6">
                        <h3>Customer/Order Information</h3>
                        <Row>
                          <Col md="3">
                            <Form.Group className="mt-4" controlId="first_name">
                              <Form.Label>First Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Ex: John..."
                                defaultValue={order.first_name}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group className="mt-4" controlId="last_name">
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Ex: Oliver..."
                                defaultValue={order.last_name}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group
                              className="mt-4"
                              controlId="customer_email"
                            >
                              <Form.Label>Customer Email</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Ex: customer@example.com"
                                defaultValue={order.customer_email}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3">
                            <Form.Group controlId="number">
                              <Form.Label>Number</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Ex: 000014"
                                name="number"
                                defaultValue={order.number}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group controlId="system_number">
                              <Form.Label>System Number</Form.Label>
                              <Form.Control
                                type="text"
                                name="system_number"
                                defaultValue={order.system_number}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="6">
                            <Form.Group controlId="notes">
                              <Form.Label>Notes</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={2}
                                defaultValue={order.notes}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="3">
                            <Form.Group controlId="country">
                              <Form.Label>Category</Form.Label>
                              <Select
                                options={categories}
                                onChange={handleCategoryChange}
                              />
                            </Form.Group>
                          </Col>
                          {products && (
                            <Col md="5">
                              <Form.Group controlId="customer_name">
                                <Form.Label>Product</Form.Label>
                                <Select
                                  options={products}
                                  onChange={handleProductChange}
                                />
                              </Form.Group>
                            </Col>
                          )}
                          <Col sm="2">
                            <Form.Group
                              controlId="customer_qty_ordered"
                              className="mr-1 ml-1"
                            >
                              <Form.Label>Qty</Form.Label>
                              <Form.Control
                                type="number"
                                min={1}
                                defaultValue={1}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="auto" className="mt-4">
                            <Button variant="primary" onClick={addOrderItem}>
                              +
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      <Col md="6" style={{ borderLeft: '1px solid black' }}>
                        <h3>Shipping Information</h3>
                        <Row className="mt-4">
                          <Col md="3">
                            <Form.Group controlId="order_date">
                              <Form.Label>Date</Form.Label>
                              <Form.Control
                                type="date"
                                defaultValue={new Date(order.order_date)
                                  .toISOString()
                                  .substr(0, 10)}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group controlId="ship_date">
                              <Form.Label>Ship Date</Form.Label>
                              <Form.Control
                                type="date"
                                defaultValue={
                                  order.ship_date == null
                                    ? null
                                    : new Date(order.ship_date)
                                        .toISOString()
                                        .substr(0, 10)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col md="4">
                            <Form.Group controlId="shipping_method">
                              <Form.Label>Shipping Method</Form.Label>
                              <Select
                                options={shippings}
                                onChange={handleShippingChange}
                                defaultValue={{
                                  label: `${order.shipping_method}`,
                                  value: `${order.shipping_method}`,
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col className="mt-4" md="2">
                            <Form.Group controlId="customer_paid_for_shipping">
                              <Form.Check
                                type="checkbox"
                                label="Paid?"
                                defaultChecked={Boolean(
                                  order.customer_paid_for_shipping
                                )}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <Form.Group controlId="street">
                              <Form.Label>Street</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Ex: 1234 S Normandie..."
                                defaultValue={
                                  order.customer_address === null ||
                                  order.customer_address === undefined
                                    ? ''
                                    : order.customer_address.street
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col md="4">
                            <Form.Group controlId="city">
                              <Form.Label>City</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Ex: Los Angeles..."
                                defaultValue={
                                  order.customer_address === null ||
                                  order.customer_address === undefined
                                    ? ''
                                    : order.customer_address.city
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col md="2"></Col>
                          <Col md="3">
                            <Form.Group controlId="state">
                              <Form.Label>State</Form.Label>
                              <Select
                                options={states}
                                onChange={handleStateChange}
                                defaultValue={{
                                  label: `${
                                    order.customer_address
                                      ? order.customer_address.state
                                        ? order.customer_address.state
                                        : ''
                                      : ''
                                  }`,
                                  value: `${
                                    order.customer_address
                                      ? order.customer_address.state
                                        ? order.customer_address.state
                                        : ''
                                      : ''
                                  }`,
                                }}
                              />
                            </Form.Group>
                          </Col>
                          <Col md="3">
                            <Form.Group controlId="postal_code">
                              <Form.Label>Postal Code</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Ex: 92263..."
                                defaultValue={
                                  order.customer_address === null ||
                                  order.customer_address === undefined
                                    ? ''
                                    : order.customer_address.postal_code
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col md="4">
                            <Form.Group controlId="country">
                              <Form.Label>Country</Form.Label>
                              <Select
                                options={countries}
                                onChange={handleCountryChange}
                                defaultValue={{
                                  label: `${
                                    order.customer_address
                                      ? order.customer_address.country
                                      : ''
                                  }`,
                                  value: `${
                                    order.customer_address
                                      ? order.customer_address.country
                                      : ''
                                  }`,
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Jumbotron>
                </Col>

                <Col md="6">
                  <h3>Product Details</h3>
                  <div>
                    <OrderItems />

                    <div className="row mt-2" style={{ float: 'right' }}>
                      <Col md="6" className="mt-3">
                        <Button
                          className="ml-2"
                          variant="success"
                          type="submit"
                        >
                          Save
                        </Button>
                      </Col>
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <h3>Summary</h3>

                  <Row>
                    <Col md="12">
                      <Table responsive="xl">
                        <thead>
                          <tr>
                            <td>
                              <b>Status</b>
                            </td>
                            <td>
                              {' '}
                              <Form.Group controlId="entered_in_system">
                                <Form.Check
                                  type="checkbox"
                                  label="Entered"
                                  defaultChecked={Boolean(
                                    order.entered_in_system
                                  )}
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group controlId="introduction_email">
                                <Form.Check
                                  type="checkbox"
                                  label="Intro Email"
                                  defaultChecked={Boolean(
                                    order.introduction_email
                                  )}
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group controlId="shipping_day_message">
                                <Form.Check
                                  type="checkbox"
                                  label="Shipping"
                                  defaultChecked={Boolean(
                                    order.shipping_day_message
                                  )}
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group controlId="follow_up_message">
                                <Form.Check
                                  type="checkbox"
                                  label="Follow Up"
                                  defaultChecked={Boolean(
                                    order.follow_up_message
                                  )}
                                />
                              </Form.Group>
                            </td>
                            <td></td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <b>Proof</b>
                            </td>
                            <td>
                              {' '}
                              <Form.Group controlId="proof_sent">
                                <Form.Check
                                  type="checkbox"
                                  label="Sent"
                                  defaultChecked={Boolean(order.proof_sent)}
                                />
                              </Form.Group>
                            </td>
                            <td>
                              <Form.Group controlId="proof_approved">
                                <Form.Check
                                  type="checkbox"
                                  label="Approved"
                                  defaultChecked={Boolean(order.proof_approved)}
                                />
                              </Form.Group>
                            </td>
                            <td>
                              {' '}
                              <Form.Group controlId="uploaded_proof">
                                <Form.Check
                                  type="checkbox"
                                  label="Uploaded"
                                  defaultChecked={Boolean(order.uploaded_proof)}
                                />
                              </Form.Group>
                            </td>
                            <td></td>
                            <td>
                              {order.date_proof_approved && (
                                <div>
                                  <span style={{ fontWeight: 'bold' }}>
                                    {new Date(
                                      order.date_proof_approved
                                    ).toLocaleDateString()}
                                  </span>
                                  <UploadModal
                                    url={order.proof_approved_url}
                                    type={'proofs'}
                                  />
                                </div>
                              )}
                              {order.proof_approved_url === '' && (
                                <Button
                                  className="btn-sm mb-3"
                                  variant="outline-primary"
                                  onClick={handleShow}
                                >
                                  Upload proof
                                </Button>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Artwork</b>
                            </td>
                            <td>
                              {' '}
                              <Form.Group controlId="artwork_uploaded">
                                <Form.Check
                                  type="checkbox"
                                  label="Uploaded"
                                  defaultChecked={
                                    order.artwork_url !== '' &&
                                    order.artwork_uploaded !== undefined
                                  }
                                />
                              </Form.Group>
                            </td>
                            <td></td>
                            <td></td>
                            <td className="text-right">
                              <span
                                className="text-warning"
                                onClick={handleShowCustomerFiles}
                                style={{ fontSize: '18pt' }}
                              >
                                <GiFiles />
                              </span>
                            </td>
                            <td>
                              {order.artwork_url !== '' && (
                                <div>
                                  <span style={{ fontWeight: 'bold' }}>
                                    {new Date(
                                      order.date_artwork_uploaded
                                    ).toLocaleDateString()}
                                  </span>
                                  <UploadModal
                                    url={order.artwork_url}
                                    type={'artwork'}
                                  />
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <b>Apparel</b>
                            </td>
                            <td>
                              <Form.Group controlId="apparel_ordered">
                                <Form.Check
                                  type="checkbox"
                                  label="Ordered"
                                  defaultChecked={Boolean(
                                    order.apparel_ordered
                                  )}
                                />
                                {order.date_apparel_ordered && (
                                  <span style={{ fontWeight: 'bold' }}>
                                    {new Date(
                                      order.date_apparel_ordered
                                    ).toLocaleDateString()}
                                  </span>
                                )}
                              </Form.Group>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>
                              <b>Shipping</b>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              {order.shipping_label === '' && (
                                <Button
                                  className="btn-sm mb-3"
                                  variant="outline-primary"
                                  onClick={handleShowShip}
                                >
                                  Upload Shipping Label
                                </Button>
                              )}
                              {order.shipping_label !== '' && (
                                <a href={order.shipping_label} download>
                                  Shipping Label
                                </a>
                              )}
                            </td>
                            <td>
                              {order.packing_slip === '' && (
                                <Button
                                  className="btn-sm mb-3"
                                  variant="outline-primary"
                                  onClick={handleShowPackSlip}
                                >
                                  Upload Packing Slip
                                </Button>
                              )}
                              {order.packing_slip !== '' && (
                                <a href={order.shipping_label} download>
                                  Packing Slip
                                </a>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div className="mt-3">
                {errors &&
                  errors.map((error, index) => (
                    <Alert variant="danger" key={index}>
                      {error}
                    </Alert>
                  ))}
              </div>
            </Form>
          </div>

          <Modal show={showPackSlip} onHide={handleClosePackSlip}>
            <Modal.Header className="bg-success text-white" closeButton>
              <Modal.Title>Packing Slip</Modal.Title>
            </Modal.Header>
            <Form onSubmit={uploadFile} id="packing_slip">
              <Modal.Body>
                <Form.Group>
                  <Form.File
                    id="file"
                    name="file"
                    label="Upload the packing slip by choosing your file below:"
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={handleClosePackSlip}
                >
                  Close
                </Button>
                <Button type="submit" variant="success">
                  Upload
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          <Modal show={showShip} onHide={handleCloseShip}>
            <Modal.Header className="bg-success text-white" closeButton>
              <Modal.Title>Shipping Label</Modal.Title>
            </Modal.Header>
            <Form onSubmit={uploadFile} id="shipping_label">
              <Modal.Body>
                <Form.Group>
                  <Form.File
                    id="file"
                    name="file"
                    label="Upload the shipping label by choosing your file below:"
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type="button"
                  variant="secondary"
                  onClick={handleCloseShip}
                >
                  Close
                </Button>
                <Button type="submit" variant="success">
                  Upload
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header className="bg-success text-white" closeButton>
              <Modal.Title>Proof Upload</Modal.Title>
            </Modal.Header>
            <Form onSubmit={uploadFile} id="proof_approved">
              <Modal.Body>
                <Form.Group>
                  <Form.File
                    id="file"
                    name="file"
                    label="Upload the approved proof by choosing your file below:"
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button type="button" variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button type="submit" variant="success">
                  Upload
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>

          <Modal
            show={showCustomerFiles}
            onHide={handleCloseCustomerFiles}
            size="lg"
            centered
          >
            <Modal.Header className="bg-warning text-dark" closeButton>
              <Modal.Title>Customer Files</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form onSubmit={uploadFile} id="customer_file">
                <Row>
                  <Col md="8">
                    <Form.Group>
                      <Form.File
                        id="file"
                        name="file"
                        label="Upload the customer file below:"
                      />
                    </Form.Group>
                  </Col>
                  <Col className="mt-4" md="4">
                    <Button type="submit" variant="success" size="sm">
                      Upload
                    </Button>
                  </Col>
                </Row>
              </Form>
              {order.customer_files && order.customer_files.length > 0 && (
                <Row>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>File</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.customer_files.map((file) => {
                        return (
                          <tr>
                            <td>
                              <img
                                src={file.file_url}
                                alt={file.file_url}
                                style={{ width: 'auto', maxHeight: '75px' }}
                              />
                            </td>
                            <td>
                              <Button variant="success" href={file.file_url}>
                                Download
                              </Button>
                              <Button
                                variant="danger"
                                onClick={(e) =>
                                  removeCustomerFile(file.file_url)
                                }
                              >
                                Delete
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Row>
              )}
            </Modal.Body>
          </Modal>
        </div>
      )}
    </Layout>
  );
};

export default EditOrders;
