import Layout from "../../components/Layout";
import React, {useState, useEffect} from "react";
import {
    Button,
    Form,
    Alert,
    Row,
    Col,
    ListGroup
} from "react-bootstrap";
import Select from "react-select";
import ProductService from "../../api/product.service";
import {toast} from "react-toastify";
import {Link, useParams} from "react-router-dom";
import {COLORS} from "../../constants/colors";
import {SIZES} from "../../constants/sizes";
import {SOURCES} from "../../constants/sources";
import {CATEGORIES} from "../../constants/listing_categories";
import {addItem, setItems, removeItem} from "../../store/productSKUs.slice";
import {useDispatch, useSelector} from "react-redux";
import {BiTrash} from "react-icons/bi";

const EditProducts = () => {

    const dispatch = useDispatch();
    let {id} = useParams();
    const {skus} = useSelector((state) => state.productSKUs);

    const [errors, setErrors] = useState(null);
    const [sizes, setSizes] = useState(null);
    const [colors, setColors] = useState(null);
    const [color, setColor] = useState(null);
    const [size, setSize] = useState(null);
    const [product, setProduct] = useState(null);
    const [sources, setSources] = useState(null);
    const [source, setSource] = useState(null);
    const [categories, setCategories] = useState(null);
    const [category, setCategory] = useState(null);

    useEffect(() => {
        setColors(COLORS.map((color, index) => ({label: color, value: color})));
        setSizes(SIZES.map((size, index) => ({label: size, value: size})));
        setSources(SOURCES.map((source, index) => ({label: source, value: source})));
        setCategories(CATEGORIES.map((category, index) => ({label: category, value: category})));
        ProductService.by_id(id).then((product) => {
            setProduct(product);
            setColor(product.color);
            setSize(product.size);
            setSource(product.source);
            setCategory(product.listing_category);
            dispatch(setItems(product.sku));
        });
    }, [id, dispatch]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            await ProductService.update({
                name: event.target.name.value,
                code: event.target.code.value,
                model_number: event.target.model_number.value,
                color: color,
                size: size,
                id: id,
                source: source,
                listing_code: event.target.listing_code.value,
                listing_category: category,
                picture_url: event.target.picture_url.value,
                sku: skus
            }).then(() => {
                toast.success("Product updated successfully!", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined
                });
            });
        } catch (e) {
            console.error(e);
            setErrors([
                e.response.data === undefined ? e.response : e.response.data.message,
            ]);
        }
    };

    const handleListingCategoryChange = (selectedOption) => {
        setCategory(selectedOption.value);
    };

    const handleColorChange = (selectedOption) => {
        setColor(selectedOption.value);
    };

    const handleSizeChange = (selectedOption) => {
        setSize(selectedOption.value);
    };

    const handleListingCodeChange = (selectedOption) => {
        setSource(selectedOption.value);
    };

    const addSKU = (event) => {
        dispatch(addItem(document.getElementById('sku').value));
        document.getElementById('sku').value = '';
    };

    const removeSKU = async (e) => {
        e.preventDefault();
        dispatch(removeItem(e.target.value));
    };

    return (
        <Layout>
            <a as={Link}
                href="/products"
                className="btn btn-info mb-2">
                Back
            </a>

            {
            product && (
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col md="6">
                            <Form.Group controlId="name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Ex: Contoured Mask" name="name"
                                    defaultValue={
                                        product.name
                                    }/>
                            </Form.Group>
                            <Form.Group controlId="code">
                                <Form.Label>Internal Code</Form.Label>
                                <Form.Control type="text" placeholder="Ex: Hyfe0001"
                                    defaultValue={
                                        product.code
                                    }/>
                            </Form.Group>
                            <Form.Group controlId="model_number">
                                <Form.Label>Model Number</Form.Label>
                                <Form.Control type="text" placeholder="Ex: Hyfe0001"
                                    defaultValue={
                                        product.model_number
                                    }/>
                            </Form.Group>
                            <Form.Group controlId="color">
                                <Form.Label>Color</Form.Label>
                                <Select options={colors}
                                    onChange={handleColorChange}
                                    defaultValue={
                                        {
                                            label: product.color,
                                            value: product.color
                                        }
                                    }/>
                            </Form.Group>
                            <Form.Group controlId="size">
                                <Form.Label>Size</Form.Label>
                                <Select options={sizes}
                                    onChange={handleSizeChange}
                                    defaultValue={
                                        {
                                            label: product.size,
                                            value: product.size
                                        }
                                    }/>
                            </Form.Group>
                            <Form.Group controlId="source">
                                <Form.Label>Source</Form.Label>
                                <Select options={sources}
                                    defaultValue={
                                        {
                                            label: product.source,
                                            value: product.source
                                        }
                                    }
                                    onChange={handleListingCodeChange}/>
                            </Form.Group>
                            <Form.Group controlId="listing_category">
                                <Form.Label>Listing Category</Form.Label>
                                <Select options={categories}
                                    defaultValue={
                                        {
                                            label: product.listing_category,
                                            value: product.listing_category
                                        }
                                    }
                                    onChange={handleListingCategoryChange}/>
                            </Form.Group>
                            <Form.Group controlId="listing_code">
                                <Form.Label>Listing Code</Form.Label>
                                <Form.Control type="text" placeholder="Ex: https://myproduct.com/123"
                                    defaultValue={
                                        product.listing_code
                                    }/>
                            </Form.Group>
                            <Form.Group controlId="picture_url">
                                <Form.Label>Picture Image</Form.Label>
                                <Form.Control type="text" placeholder="Ex: https://myproduct.com/123"
                                    defaultValue={
                                        product.picture_url
                                    }/>
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Row>
                                <Form.Group controlId="sku"
                                    as={Col}
                                    md="8">
                                    <Form.Label>SKU</Form.Label>
                                    <Form.Control type="text"/>
                                </Form.Group>
                                <Col xs="auto" className="my-1 mt-4">
                                    <Button variant="primary"
                                        onClick={addSKU}>
                                        Add
                                    </Button>
                                </Col>
                            </Form.Row>

                            <ListGroup variant="flush">
                                {
                                skus && skus.length > 0 && skus.map((sku, index) => (
                                    <ListGroup.Item key={index}>
                                        {sku}
                                        <Button variant="danger" size="sm"
                                            style={
                                                {float: 'right'}
                                            }
                                            value={sku}
                                            onClick={removeSKU}><BiTrash/></Button>
                                    </ListGroup.Item>
                                ))
                            } </ListGroup>
                        </Col>
                    </Row>


                    <Button variant="success" type="submit">
                        Save
                    </Button>

                    <div className="mt-3">
                        {
                        errors && errors.map((error, index) => (
                            <Alert variant="danger"
                                key={index}>
                                {error} </Alert>
                        ))
                    }
                        {" "} </div>
                </Form>
            )
        }
            {" "} </Layout>
    );
};

export default EditProducts;
