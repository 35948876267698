import {authedAxiosInstance} from "./api.service";

const LogService = {
    all: async () => {
        const response = await authedAxiosInstance.get("/logs");
        return response.data.data;
    },
    by_user: async (userId) => {
        const response = await authedAxiosInstance.get(`/logs/user/${userId}`);
        return response.data;
    }
};

export default LogService;
