export const columns = [
  {
    name: "Username",
    selector: "username",
    sortable: true,
  },
  {
    name: "Email",
    selector: "email",
    sortable: true,
  },
  {
    name: "Is Admin?",
    selector: "isAdmin",
    sortable: true,
    cell: (row) =>
      <div data-tag="allowRowEvents">
        <input
          type="checkbox"
          checked={row.isAdmin}
        />
      </div>
  },
  {
    name: "Created At",
    selector: "createdAt",
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {new Date(row.createdAt).toLocaleDateString()}
      </div>)
  },
];
