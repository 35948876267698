import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import { useSelector } from "react-redux";
// import { columns } from '../table-columns/purchases';
import PurchaseService from "../api/purchase.service";
import OrderService from "../api/order.service";
import { Form } from "react-bootstrap";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import "../style/purchases.css";
import { toast } from "react-toastify";
import swal from "sweetalert";
import loading from "../img/loading.gif";

const customStyles = {
  rows: {
    style: {
      minHeight: "72px", // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
      textAlign: "center"
    },
  },
  cells: {
    style: {
      paddingLeft: "8px", // override the cell padding for data cells
      paddingRight: "8px",
    },
  },
};

const ExpandableComponent = ({ data }) => {
  return (
    <div>
      <ul>
        {data.ordered_items.map((item, index) => (
          <li key={index}>
            <span>{item.product_code} </span>
            <span>- {item.product_name} </span>
            <span>
              - {item.product_size}
              &nbsp; -
            </span>
            <span>
              {" "}
              {item.source}
              &nbsp; -
            </span>
            <span> {item.product_color} </span>
            <span className="badge badge-dark">
              <strong> {item.customer_qty_ordered} </strong>
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

const Purchases = () => {
  // const columns = ;

  const { username } = useSelector((state) => state.loggedInUser);

  const [isLoading, setIsLoading] = useState(true);
  const [status, setStatus] = useState(false);
  const [data, setData] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [columns, setColumns] = useState([
    {
      name: "Product",
      sortable: true,
      cell: (row) =>
        row.ordered_items.length === 1 ? (
          <span>
            {row.ordered_items[0].product_code}-{" "}
            {row.ordered_items[0].product_name}-
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {row.ordered_items[0].product_color}
            </span>
            -<span>{row.ordered_items[0].source}</span>
          </span>
        ) : (
          <span>(Multiple)</span>
        ),
    },
    {
      name: "Size",
      sortable: true,
      cell: (row) =>
        row.ordered_items.length === 1 ? (
          <span>{row.ordered_items[0].product_size}</span>
        ) : (
          <span>(Multiple)</span>
        ),
    },
    {
      name: "Quantity Ordered",
      selector: "customer_qty_ordered",
      sortable: true,
      cell: (row) =>
        row.ordered_items.length === 1 ? (
          <span>{row.ordered_items[0].customer_qty_ordered}</span>
        ) : (
          <span>(Multiple)</span>
        ),
    },
    {
      name: "Purchased?",
      selector: "apparel_ordered",
      sortable: true,
      cell: (row) => (
        <input
          type="checkbox"
          checked={row.apparel_ordered}
          name="apparel_ordered"
          onChange={(e) => handleRow(e, row._id)}
        />
      ),
    },
    {
      name: "Date Ordered",
      selector: "date_apparel_ordered",
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          {row.date_apparel_ordered === null
            ? ""
            : new Date(row.date_apparel_ordered).toLocaleDateString()}
        </div>
      ),
    },
    {
      cell: (row) => (
        <div data-tag="allowRowEvents">
          <a className="btn btn-primary" href={`/orders/edit/${row._id}`}>
            View
          </a>
        </div>
      ),
    },
  ]);

  useEffect(() => {
    async function populate() {
      PurchaseService.all().then(async (orders) => {
        var data = await orders.filter(function (order) {
          return order.apparel_ordered === status;
        });
        setIsLoading(false);
        setData(data);
        setTableData({ columns, data });
      });
    }

    populate();
  }, [status, columns]);

  const refresh_orders = async (e) => {
    // e.preventDefault();
    setStatus(e.target.checked);
    setIsLoading(true);

    await PurchaseService.all().then(async (orders) => {
      var data = await orders.filter(function (order) {
        return order.apparel_ordered === e.target.checked;
      });
      setIsLoading(false);
      setData(data);
      setColumns(columns);
      setTableData({ columns, data });
    });
  };

  const handleRow = async (event, id) => {
    var name = event.target.name;
    var prop_value = event.target.checked;
    var order = {
      id: id,
    };
    order[name] = prop_value;
    swal({
      title: "Are you sure to purchase this items?",
      text: "This will mark the items as purchased.",
      icon: "warning",
      buttons: true,
      dangerMode: false,
    }).then(async (willDelete) => {
      if (willDelete) {
        await OrderService.update(order).then(() => {
          toast.success("Purchase updated successfully!", {
            position: "top-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          PurchaseService.all().then(async (orders) => {
            var data = await orders.filter(function (order) {
              return order.apparel_ordered === status;
            });
            setData(data);
            setTableData({ columns, data });
          });
        });
      }
    });
  };

  return (
    <Layout>
      {" "}
      {username && (
        <div className="mb-4">

          {isLoading && (
            <div className="text-center">
              <img src={loading} alt={'loading'} />
            </div>
          )
          }

          {!isLoading && tableData && (
            <div>
              <div className="mb-4 text-right">
                <Form.Group controlId="purchasedFilter">
                  <Form.Check
                    type="checkbox"
                    label="Purchased?"
                    checked={status}
                    onChange={refresh_orders}
                  />
                </Form.Group>
              </div>

              <DataTableExtensions
                {...tableData}
                filterPlaceholder={"Search Purchases"}
                print={false}
              >
                <DataTable
                  className="mt-4"
                  columns={columns}
                  data={data}
                  striped={true}
                  pagination
                  customStyles={customStyles}
                  expandableRows
                  expandableRowDisabled={(row) =>
                    row.ordered_items.length === 1
                  }
                  expandableRowsComponent={<ExpandableComponent />}
                  fixedHeader={true}
                />
              </DataTableExtensions>
            </div>
          )}{" "}
        </div>
      )}{" "}
    </Layout>
  );
};

export default Purchases;
