import Layout from '../../components/Layout';
import OrderItems from '../orders/order_items';
import React, { useState, useEffect } from 'react';
import {
  Button,
  Form,
  Alert,
  Col,
  Row,
  Jumbotron,
  Table,
} from 'react-bootstrap';
import Select from 'react-select';
import ProductService from '../../api/product.service';
import OrderService from '../../api/order.service';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { SHIPPING_TYPE } from '../../constants/shipping_type';
import { US_STATES } from '../../constants/us_states';
import { CATEGORIES } from '../../constants/listing_categories';
import { addItem, destroyLineItems } from '../../store/orderItems.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import '../../style/custom_table.css';

const AddOrders = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { line_items } = useSelector((state) => state.orderItems);

  const [errors, setErrors] = useState(null);
  const [products, setProducts] = useState(null);
  const [shippings, setShippings] = useState(null);
  const [states, setStates] = useState(null);
  const [state, setState] = useState(null);
  const [countries, setCountries] = useState(null);
  const [country, setCountry] = useState(null);
  const [product, setProduct] = useState(null);
  const [shipping, setShipping] = useState(null);
  const [categories, setCategories] = useState(null);

  const handleProductChange = (selectedOption) => {
    setProduct(JSON.parse(selectedOption.value));
  };

  const handleShippingChange = (selectedOption) => {
    setShipping(selectedOption.value);
  };

  const handleStateChange = (selectedOption) => {
    setState(selectedOption.value);
  };

  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption.value);
  };

  const handleCategoryChange = (selectedOption) => {
    // by_category
    ProductService.by_category(selectedOption.value).then((products) => {
      setProducts(
        products.map((product, index) => ({
          label: `${product.name} - ${product.color} - ${product.size} - ${product.source}`,
          value: `${JSON.stringify(product)}`,
        }))
      );
    });
  };

  useEffect(() => {
    ProductService.all().then((products) => {
      setProducts(
        products.map((product, index) => ({
          label: `${product.name} - ${product.color} - ${product.size} - ${product.source}`,
          value: `${JSON.stringify(product)}`,
        }))
      );
    });
    setShippings(
      SHIPPING_TYPE.map((method, index) => ({ label: method, value: method }))
    );
    setStates(
      US_STATES.map((states, index) => ({
        label: states.name,
        value: states.code,
      }))
    );
    setCategories(
      CATEGORIES.map((category, index) => ({
        label: category,
        value: category,
      }))
    );
    setCountries([
      {
        label: 'United States Of America',
        value: 'USA',
      },
    ]);
    dispatch(destroyLineItems());
  }, [dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      var order = {
        number: event.target.number.value,
        system_number: event.target.system_number.value,
        order_date: event.target.order_date.value,
        ship_date: event.target.ship_date.value,
        customer_paid_for_shipping: Boolean(
          event.target.customer_paid_for_shipping.checked
        ),
        first_name: event.target.first_name.value,
        last_name: event.target.last_name.value,
        customer_email: event.target.customer_email.value,
        shipping_method: shipping,
        ordered_items: line_items,
        customer_address: null,
      };

      if (state) {
        order.customer_address = {
          street: event.target.street.value,
          city: event.target.city.value,
          state: state,
          country: country,
          postal_code: event.target.postal_code.value,
        };
      }

      await OrderService.add(order).then((created_order) => {
        toast.success('Order created successfully!', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        dispatch(destroyLineItems());
        event.target.reset();
        setTimeout(() => {
          history.push('/orders/edit/' + created_order.data._id);
        }, 2000);
      });
    } catch (e) {
      setErrors([
        e.response.data === undefined
          ? e.response
          : errors
          ? errors
          : e.response.data.error === undefined
          ? e.response.data.message
          : e.response.data.error.errors,
      ]);
    }
  };

  const addOrderItem = (event) => {
    event.preventDefault();
    var qty = document.getElementById('customer_qty_ordered').value;
    if (qty < 1) {
      toast.error(`Quantity can't be lower than 1.`, {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    var order_item = {
      product_name: product.name,
      product_code: product.code,
      product_size: product.size,
      product_color: product.color,
      customer_qty_ordered: qty,
      source: product.source,
    };
    dispatch(addItem(order_item));
    document.getElementById('customer_qty_ordered').value = 1;
  };

  return (
    <Layout>
      <Button
        as={Link}
        onClick={(event) => history.push('/orders')}
        className="btn btn-info mb-3"
      >
        Back
      </Button>

      {errors &&
        errors.map((error, index) => (
          <Alert variant="danger" key={index} className="mt-3">
            {' '}
            {error}{' '}
          </Alert>
        ))}

      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md="12">
            <Jumbotron>
              <Row>
                <Col md="6">
                  <h3>Customer/Order Information</h3>
                  <Row>
                    <Col md="3">
                      <Form.Group className="mt-4" controlId="first_name">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" placeholder="Ex: John..." />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group className="mt-4" controlId="last_name">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder="Ex: Oliver..." />
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group className="mt-4" controlId="customer_email">
                        <Form.Label>Customer Email</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Ex: customer@example.com"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <Form.Group controlId="number">
                        <Form.Label>Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Ex: 000014"
                          name="number"
                        />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group controlId="system_number">
                        <Form.Label>System Number</Form.Label>
                        <Form.Control type="text" name="system_number" />
                      </Form.Group>
                    </Col>
                    <Col md="6">
                      <Form.Group controlId="notes">
                        <Form.Label>Notes</Form.Label>
                        <Form.Control as="textarea" rows={2} />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <Form.Group controlId="country">
                        <Form.Label>Category</Form.Label>
                        <Select
                          options={categories}
                          onChange={handleCategoryChange}
                        />
                      </Form.Group>
                    </Col>
                    {products && (
                      <Col md="5">
                        <Form.Group controlId="customer_name">
                          <Form.Label>Product</Form.Label>
                          <Select
                            options={products}
                            onChange={handleProductChange}
                          />
                        </Form.Group>
                      </Col>
                    )}
                    <Col md="2">
                      <Form.Group
                        controlId="customer_qty_ordered"
                        className="mr-1 ml-1"
                      >
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control type="number" min={1} defaultValue={1} />
                      </Form.Group>
                    </Col>
                    <Col md="auto" className="my-1 mt-4">
                      <Button variant="primary" onClick={addOrderItem}>
                        +
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col md="6" style={{ borderLeft: '1px solid black' }}>
                  <h3>Shipping Information</h3>
                  <Row className="mt-4">
                    <Col md="3">
                      <Form.Group controlId="order_date">
                        <Form.Label>Date</Form.Label>
                        <Form.Control type="date" />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group controlId="ship_date">
                        <Form.Label>Ship Date</Form.Label>
                        <Form.Control type="date" />
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group controlId="shipping_method">
                        <Form.Label>Shipping Method</Form.Label>
                        <Select
                          options={shippings}
                          onChange={handleShippingChange}
                        />
                      </Form.Group>
                    </Col>
                    <Col className="mt-4" md="2">
                      <Form.Group controlId="customer_paid_for_shipping">
                        <Form.Check type="checkbox" label="Paid?" />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Form.Group controlId="street">
                        <Form.Label>Street</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Ex: 1234 S Normandie..."
                        />
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group controlId="city">
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Ex: Los Angeles..."
                        />
                      </Form.Group>
                    </Col>
                    <Col md="2"></Col>
                    <Col md="3">
                      <Form.Group controlId="state">
                        <Form.Label>State</Form.Label>
                        <Select options={states} onChange={handleStateChange} />
                      </Form.Group>
                    </Col>
                    <Col md="3">
                      <Form.Group controlId="postal_code">
                        <Form.Label>Postal Code</Form.Label>
                        <Form.Control type="text" placeholder="Ex: 92263..." />
                      </Form.Group>
                    </Col>
                    <Col md="4">
                      <Form.Group controlId="country">
                        <Form.Label>Country</Form.Label>
                        <Select
                          options={countries}
                          onChange={handleCountryChange}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Jumbotron>
          </Col>
          <Col md="6">
            <h3>Product Details</h3>
            <div>
              <OrderItems />

              <div className="row mt-2" style={{ float: 'right' }}>
                <Button
                  className="text-right"
                  variant="success"
                  type="submit"
                  disabled={line_items.length === 0}
                >
                  Save
                </Button>
              </div>
            </div>
          </Col>
          <Col md="6">
            <h3>Summary</h3>

            <Row>
              <Col md="12">
                <Table responsive="xl">
                  <thead>
                    <tr>
                      <td>
                        <b>Status</b>
                      </td>
                      <td>
                        {' '}
                        <Form.Group controlId="entered_in_system">
                          <Form.Check
                            type="checkbox"
                            label="Entered"
                            disabled={true}
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group controlId="introduction_email">
                          <Form.Check
                            type="checkbox"
                            label="Intro Email"
                            disabled={true}
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group controlId="shipping_day_message">
                          <Form.Check
                            type="checkbox"
                            label="Shipping"
                            disabled={true}
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group controlId="follow_up_message">
                          <Form.Check
                            type="checkbox"
                            label="Follow Up"
                            disabled={true}
                          />
                        </Form.Group>
                      </td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b>Proof</b>
                      </td>
                      <td>
                        <Form.Group controlId="proof_sent">
                          <Form.Check
                            type="checkbox"
                            label="Sent"
                            disabled={true}
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group controlId="proof_approved">
                          <Form.Check
                            type="checkbox"
                            label="Approved"
                            disabled={true}
                          />
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group controlId="uploaded_proof">
                          <Form.Check
                            type="checkbox"
                            label="Uploaded"
                            disabled={true}
                          />
                        </Form.Group>
                      </td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        <b>Artwork</b>
                      </td>
                      <td>
                        <Form.Group controlId="artwork_uploaded">
                          <Form.Check
                            type="checkbox"
                            label="Uploaded"
                            disabled={true}
                          />
                        </Form.Group>
                      </td>
                      <td></td>
                      <td></td>
                      <td className="text-right"></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        <b>Apparel</b>
                      </td>
                      <td>
                        <Form.Group controlId="apparel_ordered">
                          <Form.Check
                            type="checkbox"
                            label="Ordered"
                            disabled={true}
                          />
                        </Form.Group>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>
                        <b>Shipping</b>
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Layout>
  );
};

export default AddOrders;
