import { axiosInstance, authedAxiosInstance } from "./api.service";

const AuthService = {
  login: async ({ email, password }) => {
    const response = await axiosInstance.post("/auth/login", {
      email,
      password,
    });
    authedAxiosInstance.defaults.headers[
      "Authorization"
    ] = `Bearer ${response.data.token}`;
    return response.data;
  },
  me: async () => {
    const response = await authedAxiosInstance.get("/auth/me");
    return response.data;
  },
  logout: async () => {
    const response = await authedAxiosInstance.post("/auth/logout");
    return response.data;
  },
};

export default AuthService;
