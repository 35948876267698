import { authedAxiosInstance } from "./api.service";

const OrderService = {
  all: async () => {
    const response = await authedAxiosInstance.get("/orders");
    return response.data.data;
  },
  get_by_status: async ({ status }) => {
    const response = await authedAxiosInstance.get("/orders", { params: { status: status } });
    return response.data.data;
  },
  ready_to_enter: async () => {
    const response = await authedAxiosInstance.post("/orders/ready-to-enter");
    return response.data.data;
  },
  search: async ({ params }) => {
    const response = await authedAxiosInstance.post("/orders/search", params);
    return response.data.data;
  },
  by_id: async (id) => {
    const response = await authedAxiosInstance.get(`/orders/${id}`);
    return response.data.data;
  },
  add: async ({
    number,
    system_number,
    order_date,
    ship_date,
    customer_paid_for_shipping,
    first_name,
    last_name,
    shipping_method,
    ordered_items,
    customer_address
  }) => {
    const response = await authedAxiosInstance.post("/orders/add", {
      number,
      system_number,
      order_date,
      ship_date,
      customer_paid_for_shipping,
      first_name,
      last_name,
      shipping_method,
      ordered_items,
      customer_address
    });
    return response.data;
  },
  update: async ({
    id,
    number,
    system_number,
    order_date,
    ship_date,
    customer_paid_for_shipping,
    first_name,
    last_name,
    ordered_items,
    introduction_email,
    proof_sent,
    proof_approved,
    artwork_uploaded,
    apparel_ordered,
    notes,
    shipping_method,
    shipping_day_message,
    uploaded_proof,
    entered_in_system,
    follow_up_message,
    customer_address
  }) => {
    const response = await authedAxiosInstance.put(`/orders/${id}`, {
      number,
      system_number,
      order_date,
      ship_date,
      customer_paid_for_shipping,
      first_name,
      last_name,
      ordered_items,
      introduction_email,
      proof_sent,
      proof_approved,
      artwork_uploaded,
      apparel_ordered,
      notes,
      shipping_method,
      shipping_day_message,
      uploaded_proof,
      entered_in_system,
      follow_up_message,
      customer_address
    });
    return response.data;
  },
  complete: async ({ id }) => {
    const response = await authedAxiosInstance.put(`/orders/${id}/complete`);
    return response.data;
  },
  cancel: async ({ id }) => {
    const response = await authedAxiosInstance.put(`/orders/${id}/cancel`);
    return response.data;
  },
  delete: async ({ id }) => {
    const response = await authedAxiosInstance.delete(`/orders/${id}`);
    return response.data;
  },
  upload: async ({ order_id, file, type }) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", type);
    const response = await authedAxiosInstance.put(`orders/upload/${order_id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  },
  remove_upload: async ({ order_id, url, type }) => {
    const response = await authedAxiosInstance.put(`orders/remove_upload/${order_id}`, {
      order_id,
      url,
      type,
    });
    return response.data;
  },
};

export default OrderService;
