import {authedAxiosInstance} from "./api.service";

const UserService = {
    all: async () => {
        const response = await authedAxiosInstance.get("/users");
        return response.data.data;
    },
    by_id: async (id) => {
        const response = await authedAxiosInstance.get(`/users/${id}`);
        return response.data.data;
    },
    add: async (
        {username, email, password, isAdmin}
    ) => {
        const response = await authedAxiosInstance.post('/users/add', {username, email, password, isAdmin});
        return response.data.data;
    },
    update: async (
        {
            id,
            username,
            email,
            password,
            isAdmin
        }
    ) => {
        const response = await authedAxiosInstance.put(`/users/${id}`, {username, email, password, isAdmin});
        return response.data.data;
    },
    delete: async (id) => {
        const response = await authedAxiosInstance.delete(`/users/${id}`);
        return response.data.data;
    }
};

export default UserService;
