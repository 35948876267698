import Layout from "../components/Layout";
import { Card, Button, Modal, Form, Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import NoteService from "../api/note.service";

const Notes = () => {
  const [data, setData] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    NoteService.all().then((notes) => {
      setData(notes);
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    NoteService.add({ content: event.target.content.value }).then(() => {
      NoteService.all().then((notes) => {
        setData(notes);
        setShow(false);
        event.target.content.value = "";
      });
    });
  };

  const deleteNote = async (event) => {
    if (window.confirm("Are you sure to delete this note?")) {
      NoteService.delete(event.target.id).then(() => {
        NoteService.all().then((notes) => {
          setData(notes);
        });
      });
    }
  };

  return (
    <Layout>
      <Button className="mt-2 mb-4" variant={"warning"} onClick={handleShow}>
        Add
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmit}>
          <Modal.Header className="bg-warning" closeButton>
            <Modal.Title>New Note</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="content">
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter your notes here..."
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      {data &&
        data.map((note, index) => (
          <Row>
            <Col>
              <Card className="mt-2 mb-2" key={index}>
                <Card.Body>
                  <p>
                    <Button
                      className="btn-sm text-left"
                      variant="danger"
                      onClick={deleteNote}
                      id={note._id}
                    >
                      Delete
                    </Button>
                    <span
                      className="text-right mb-2 text-muted"
                      style={{ float: "right" }}
                    >
                      {new Date(note.createdAt).toLocaleDateString()}
                    </span>
                  </p>
                  <hr />
                  <Card.Text> {note.content} </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        ))}{" "}
    </Layout>
  );
};

export default Notes;
