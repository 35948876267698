import { combineReducers } from "@reduxjs/toolkit";
import loggedInUserReducer from "./loggedInUser.slice";
import orderItemsReducer from "./orderItems.slice";
import productSKUs from "./productSKUs.slice";

const rootReducer = combineReducers({
  loggedInUser: loggedInUserReducer,
  orderItems: orderItemsReducer,
  productSKUs: productSKUs
});

export default rootReducer;
