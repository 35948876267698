import { Badge } from 'react-bootstrap';

export const columns = [
    {
        name: 'Action',
        selector: 'action',
        sortable: true,
        cell: (row) => row.module === 'login' || row.module === 'auth' ? (<Badge variant={'success'}>
            {row.module.toUpperCase()}
        </Badge>) : (<Badge variant={
            row.action === 'POST' ? 'success' : row.action === 'PUT' ? 'warning' : 'danger'
        }> {
                row.action.replace('POST', 'ADD').replace('PUT', 'EDIT').replace('DELETE', 'DELETE')
            } </Badge>)
    }, {
        name: 'Module',
        selector: 'module',
        sortable: true,
        cell: (row) => (row.module === 'login' ? '' : row.module)
    }, {
        name: 'Reference Id',
        selector: 'reference_id',
        sortable: true,
        cell: (row) => (<div data-tag="allowRowEvents"> {' '}
            {
                row.module !== 'login' && (<a href={
                    `../../${row.module
                    }/${row.action.replace('POST', 'add').replace('PUT', 'edit').replace('DELETE', 'edit')
                    }/${row.reference_id
                    }`
                }> {' '}
                    {
                        row.reference_id.length > 100 ? row.reference_id.substring(0, 99) : row.reference_id
                    }
                    {' '} </a>)
            }
            {' '} </div>)
    }, {
        name: 'Date & Time',
        selector: 'createdAt',
        sortable: true,
        cell: (row) => (<div data-tag="allowRowEvents">
            <p> {' '}
                {
                    new Date(row.createdAt).toLocaleDateString()
                }
                -{' '}
                {
                    new Date(row.createdAt).toLocaleTimeString()
                }
                {' '} </p>
        </div>)
    },
];
