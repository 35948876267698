import { authedAxiosInstance } from "./api.service";

const PurchaseService = {
  all: async () => {
    const response = await authedAxiosInstance.get("/purchases");
    return response.data.data;
  },
};

export default PurchaseService;
