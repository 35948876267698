import React from "react";
import Layout from "./Layout";

import { useSelector } from "react-redux";

const Home = () => {
  const { username } = useSelector((state) => state.loggedInUser);

  return <Layout> {username && <p>Welcome {username}</p>} </Layout>;
};

export default Home;
