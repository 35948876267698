import { Button, Modal } from "react-bootstrap";
import React, { useState } from "react";
import { FiDownload } from "react-icons/fi";
import OrderService from "../api/order.service";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const UploadModal = ({ url, type }) => {
  let { id } = useParams();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const removeFile = async () => {
    await OrderService.remove_upload({ order_id: id, url: url, type: type })
      .then(() => {
        toast.success("Deleted successfully!", {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        setShow(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((e) => {
        toast.error(
          e.response.data === undefined ? e.response : e.response.data.message,
          {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          }
        );
      });
  };

  return (
    <>
      <span className="btn-link" size="sm" onClick={handleShow} style={{cursor: 'pointer'}}>
        <FiDownload />
      </span>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="bg-warning text-dark" closeButton>
          <Modal.Title>Upload {type.toUpperCase()} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Please choose one of the options below.</p>

          <img
            className="text-center"
            src={url}
            alt={url}
            style={{
              width: "auto",
              maxHeight: "300px",
            }}
          />

          <p>
            <Button
              style={{ float: "left" }}
              variant="danger"
              onClick={removeFile}
            >
              Delete
            </Button>
            <Button style={{ float: "right" }} variant="success" href={url}>
              Download
            </Button>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UploadModal;
