import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navbar, Nav, Button, ButtonToolbar } from "react-bootstrap";
import { Link, useRouteMatch } from "react-router-dom";
import { destroyLoggedInUser } from "../store/loggedInUser.slice";
import AuthService from "../api/auth.service";

import { useHistory } from "react-router-dom";

const Header = () => {
    let match = useRouteMatch();
    const history = useHistory();
    const dispatch = useDispatch();
    const { username, isAdmin } = useSelector((state) => state.loggedInUser);

    const logout = async () => {
        await AuthService.logout().then(() => {
            dispatch(destroyLoggedInUser());
            history.push("/");
        });
    };

    return (
        <Navbar expand="lg" bg="dark" variant="dark">
            <Navbar.Brand as={Link}
                to="/">
                <h2>District Printing</h2>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="sidebarMenu" />{" "}
            {
                username && (
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto">
                        <Nav.Link as={Link} to="/search" className={
                                    match.path.includes("/search") ? "active" : ""
                                }>
                                Search
                        </Nav.Link>
                        <Nav.Link as={Link} to="/ready-to-enter" className={
                                    match.path.includes("/ready-to-enter") ? "active" : ""
                                }>
                                Ready to Enter
                        </Nav.Link>
                            <Nav.Link as={Link} to="/orders" className={
                                    match.path.includes("/orders") ? "active" : ""
                                }>
                                Dashboard
                        </Nav.Link>
                            <Nav.Link as={Link}
                                to="/products"
                                className={
                                    match.path.includes("/products") ? "active" : ""
                                }>
                                Products
                        </Nav.Link>
                            <Nav.Link as={Link}
                                to="/purchases"
                                className={
                                    match.path.includes("/purchases") ? "active" : ""
                                }>
                                Purchases
                        </Nav.Link>
                            <Nav.Link as={Link}
                                to="/approved_proofs"
                                className={
                                    match.path.includes("/approved_proofs") ? "active" : ""
                                }>
                                Approved Proofs
                        </Nav.Link>
                            <Nav.Link as={Link}
                                to="/notes"
                                className={
                                    match.path.includes("/notes") ? "active" : ""
                                }>
                                Notes
                        </Nav.Link>

                            {
                                isAdmin && <Nav.Link as={Link}
                                    to="/users"
                                    className={
                                        match.path.includes("/users") ? "active-adm" : ""
                                    }>
                                    Users
                        </Nav.Link>
                            }

                            {
                                isAdmin && <Nav.Link as={Link}
                                    to="/logs"
                                    className={
                                        match.path.includes("/logs") ? "active-adm" : ""
                                    }>
                                    Logs
                        </Nav.Link>
                            } </Nav>

                        <ButtonToolbar>
                            <Button variant="primary"
                                onClick={logout}>
                                Logout
                        </Button>
                        </ButtonToolbar>
                    </Navbar.Collapse>
                )
            }
            {
                !username && (
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto"></Nav>
                        <Nav className="header-left-nav">
                            <ButtonToolbar>
                                <Button as={Link}
                                    to="/login"
                                    variant="secondary">
                                    Log In
                            </Button>
                            </ButtonToolbar>
                        </Nav>
                    </Navbar.Collapse>
                )
            }
            {" "} </Navbar>
    );
};

export default Header;
