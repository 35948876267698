import React from "react";
import { Container } from "react-bootstrap";
import Header from "./Header";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Layout = (props) => {
  return (
    <div>
      <Header />
      <Container className="mt-4" fluid>
        {props.children}{" "}
      </Container>
      <ToastContainer />
    </div>
  );
};

export default Layout;
