import DataTable from "react-data-table-component";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { removeItem } from "../../store/orderItems.slice";
import '../../style/custom_table.css';

const customStyles = {
  rows: {
    style: {
      maxHeight: "120px", // override the row height
    }
  },
  headCells: {
    style: {
      paddingLeft: "8px", // override the cell padding for head cells
      paddingRight: "8px",
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
    },
  },
};

const OrderItems = () => {
  const dispatch = useDispatch();

  const { line_items } = useSelector((state) => state.orderItems);

  const [columns, setColumns] = useState([
    {
      name: "Product Code",
      selector: "product_code",
      sortable: true,
      maxWidth: '15%',
      cell: (row) => (
        <div data-tag="allowRowEvents" style={{ maxHeight: '50px !important' }}>
          {row.product_code}
        </div>),
    },
    {
      name: "Product Name",
      selector: "product_name",
      sortable: true,
      maxWidth: '45%',
      cell: (row) => (
        <div data-tag="allowRowEvents" style={{ maxHeight: '50px !important' }}>
          {row.product_name}
        </div>),
    },
    {
      name: "Size",
      selector: "product_size",
      sortable: true,
      maxWidth: '10%',
    },
    {
      name: "Color",
      selector: "product_color",
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents" style={{ maxHeight: '50px !important' }}>
          <span>{row.product_color}</span>
        </div>
      ),
      maxWidth: '10%',
    },
    {
      name: "Quantity",
      selector: "customer_qty_ordered",
      sortable: true,
      maxWidth: '10%',
    },
    {
      cell: (row) => (
        <div data-tag="allowRowEvents" style={{ maxHeight: '50px !important' }}>
          <button
            className="btn btn-danger btn-sm"
            id={JSON.stringify(row)}
            onClick={(e) => deleteOrders(e)}
          >
            X
          </button>
        </div>
      ),
      maxWidth: '10%',
    },
  ]);
  const [data, setData] = useState(null);

  const deleteOrders = async (e) => {
    e.preventDefault();

    dispatch(removeItem(JSON.parse(e.target.id)));
    setData(line_items);
    setColumns(columns);
  };

  useEffect(() => {
    setData(line_items);
    setTimeout(() => {
      if (document.getElementsByClassName('jPffGe').length > 0) {
        document.getElementsByClassName('jPffGe')[0].className = "sc-hHftDr abcd";
      }
    }, 100);
  }, [line_items]);

  return (
    <div id="order_items">
      {" "}
      {data && (
        <DataTable
          className="mt-4"
          columns={columns}
          data={data}
          pagination
          highlightOnHover
          customStyles={customStyles}
          subHeader={true}
          dense={true}
          paginationPerPage={3}
        />
      )}{" "}
    </div>
  );
};

export default OrderItems;
