import Layout from "../components/Layout";
import { useSelector } from "react-redux";
import UserService from "../api/user.service";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { columns } from "../table-columns/users";
import { Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import swal from "sweetalert";
import { toast } from "react-toastify";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import loading from "../img/loading.gif";

const customStyles = {
    rows: {
        style: {
            minHeight: "72px", // override the row height
        }
    },
    headCells: {
        style: {
            paddingLeft: "8px", // override the cell padding for head cells
            paddingRight: "8px"
        }
    },
    cells: {
        style: {
            paddingLeft: "8px", // override the cell padding for data cells
            paddingRight: "8px"
        }
    }
};

const Users = () => {
    const history = useHistory();
    const { username } = useSelector((state) => state.loggedInUser);
    const [tableData, setTableData] = useState(null);
    const [data, setData] = useState(null);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        UserService.all().then(async (data) => {
            setData(data);
            setTableData({ columns, data });
        });
    }, []);

    const handleChange = (state) => { // You can use setState or dispatch with something like Redux so we can use the retrieved data
        setRows(state.selectedRows);
    };

    const loadProduct = (state) => {
        history.push("/users/edit/" + state._id);
    };

    const deleteRows = () => {
        swal({
            title: "Are you sure?",
            text: rows.length + " users are going to be deleted.",
            icon: "error",
            buttons: true,
            dangerMode: true
        }).then(async (willDelete) => {
            if (willDelete) {
                rows.forEach(async (product) => {
                    await UserService.delete(product._id).then(() => {
                        toast.success("User deleted successfully!", {
                            position: "top-center",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: false,
                            draggable: true,
                            progress: undefined
                        });
                    });
                });
                UserService.all().then((data) => {
                    setData(data);
                    setTableData({ columns, data });
                });
            }
        });
    };

    return (
        <Layout> {" "}
            {
                username && (
                    <div>
                        <div className="mb-4">
                            <Button as={Link}
                                to="/users/add"
                                className="mr-2 ml-2"
                                variant={"primary"}>
                                Add
                        </Button>
                            <Button disabled={
                                rows.length === 0
                            }
                                className="mr-2 ml-2"
                                variant={"danger"}
                                onClick={deleteRows}>
                                Delete
                        </Button>
                        </div>


                        {!data && (
                            <div className="text-center">
                                <img src={loading} alt={'loading'} />
                            </div>
                        )
                        }

                        {
                            data && tableData && (
                                <DataTableExtensions {...tableData}
                                    filterPlaceholder={"Search Users..."}
                                    print={false}>
                                    <DataTable className="mt-4"
                                        columns={columns}
                                        data={data}
                                        pagination
                                        highlightOnHover
                                        customStyles={customStyles}
                                        selectableRows
                                        onSelectedRowsChange={handleChange}
                                        onRowClicked={loadProduct}
                                        subHeader={true}
                                        striped={true}
                                        fixedHeader={true} />
                                </DataTableExtensions>
                            )
                        }
                        {" "} </div>
                )
            }
            {" "} </Layout>
    );
};

export default Users;
