import { authedAxiosInstance } from './api.service';

const ProductService = {
  all: async () => {
    const response = await authedAxiosInstance.get('/products');
    return response.data.data;
  },
  by_id: async (id) => {
    const response = await authedAxiosInstance.get(`/products/${id}`);
    return response.data.data;
  },
  by_category: async (category) => {
    const response = await authedAxiosInstance.get(
      `/products/category/${category}`
    );
    return response.data.data;
  },
  add: async ({
    name,
    code,
    model_number,
    color,
    size,
    source,
    listing_code,
    listing_category,
    picture_url,
    sku,
  }) => {
    const response = await authedAxiosInstance.post(`/products/add`, {
      name,
      code,
      model_number,
      color,
      size,
      source,
      listing_code,
      listing_category,
      picture_url,
      sku,
    });
    return response.data;
  },
  update: async ({
    name,
    code,
    model_number,
    color,
    size,
    id,
    source,
    listing_code,
    listing_category,
    picture_url,
    sku,
  }) => {
    const response = await authedAxiosInstance.put(`/products/${id}`, {
      name,
      code,
      model_number,
      color,
      size,
      source,
      listing_code,
      listing_category,
      picture_url,
      sku,
    });
    return response.data;
  },
  delete: async (id) => {
    const response = await authedAxiosInstance.delete(`/products/${id}`);
    return response.data;
  },
  bulk: async ({ file }) => {
    const formData = new FormData();
    formData.append('file', file);
    const response = await authedAxiosInstance.post(`products/bulk`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  },
};

export default ProductService;
