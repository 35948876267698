import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  skus: [],
};

const productSKUs = createSlice({
  name: "productSKUs",
  initialState,
  reducers: {
    addItem(state, action) {
      state.skus.push(action.payload);
    },
    removeItem(state, action) {
      var index = state.skus.indexOf(action.payload);
      if (index > -1) {
        state.skus.splice(index, 1);
      }
    },
    setItems(state, action) {
      state.skus = action.payload;
    },
    destroyLineItems(state, action) {
      state.skus = [];
    },
  },
});

export const {
  addItem,
  removeItem,
  destroyLineItems,
  setItems,
} = productSKUs.actions;

export default productSKUs.reducer;
