import "./App.css";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../components/Home";
import Login from "../components/Login";
import Products from "../pages/Products";
import AddOrders from "../pages/orders/add";
import EditOrders from "../pages/orders/order";
import AddProducts from "../pages/products/add";
import EditProducts from "../pages/products/product";
import EditUser from "../pages/users/user";
import AddUser from "../pages/users/add";
import Orders from "../pages/Orders";
import ReadyToEnter from "../pages/ReadyToEnter";
import ApprovedProofs from "../pages/ApprovedProofs";
import Notes from "../pages/Notes";
import Users from "../pages/Users";
import Logs from "../pages/Logs";
import Search from "../pages/Search";
import Purchases from "../pages/Purchases";
import NotFound from "../pages/NotFound";
import JwtService from "../api/jwt.service";
import AuthService from "../api/auth.service";
import { setLoggedInUser } from "../store/loggedInUser.slice";

const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        if (JwtService.getToken()) {
            AuthService.me().then((loggedInUser) => {
                dispatch(setLoggedInUser(loggedInUser));
            });
        }
    }, [dispatch]);

    return (
        <Router>
            <Switch> {/* Orders Route */}
                <Route path="/orders/edit/:id"
                    component={EditOrders} />
                <Route path="/orders/add"
                    component={AddOrders} />{" "}
                {/* Products Route */}
                <Route path="/products/edit/:id"
                    component={EditProducts} />
                <Route path="/products/add"
                    component={AddProducts} />
                <Route path="/approved_proofs"
                    component={ApprovedProofs} />
                <Route path="/users/add"
                    component={AddUser} />
                <Route path="/users/edit/:id"
                    component={EditUser} />
                <Route path="/users"
                    component={Users} />
                <Route path="/logs"
                    component={Logs} />
                <Route path="/notes"
                    component={Notes} />
                <Route path="/purchases"
                    component={Purchases} />
                <Route path="/orders"
                    component={Orders} />
                <Route path="/search"
                    component={Search} />
                <Route path="/products"
                    component={Products} />
                <Route path="/ready-to-enter"
                    component={ReadyToEnter} />
                <Route path="/login"
                    component={Login} />
                <Route path="/"
                    component={Home} />
                <Route component={NotFound} />
            </Switch>
        </Router>
    );
};

export default App;
