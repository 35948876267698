import Layout from "../../components/Layout";
import React, {useState, useEffect} from "react";
import {Button, Form, Row, Col} from "react-bootstrap";
import UserService from "../../api/user.service";
import LogService from "../../api/log.service";
import {toast} from "react-toastify";
import {Link, useParams} from "react-router-dom";
import {columns} from "../../table-columns/logs";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

const EditUser = () => {

    let {id} = useParams();
    const [user, setUser] = useState(null);
    const [logs, setLogs] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [logFilter, setLogFilter] = useState('login');

    useEffect(() => {
        UserService.by_id(id).then(async (user) => {
            setUser(user);
        });
        LogService.by_user(id).then(async (logs) => {
            var data = await logs.data;
            setLogs(logs.data);
            setTableData({columns, data});
        });
    }, [id, logFilter]);

    const handleSubmit = async (event) => {

        event.preventDefault();

        await UserService.update({
            id: id,
            username: event.target.username.value,
            email: event.target.email.value,
            password: event.target.password.value,
            isAdmin: event.target.isAdmin.checked
        }).then(() => {
            toast.dismiss();
            toast.success("User updated successfully!", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined
            });
        }).catch((e) => {
            toast.error(e.response.user === undefined ? e.response : e.response.user.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined
            });
        });
    }

    const refresh_orders = async (event) => {
        event.preventDefault();

        var stat = event.target.id;
        setLogFilter(stat);
        stat = 'login' ? 'all' : 'login';
        var className = event.target.className;
        document.getElementById(stat).className = document.getElementById(stat).className.replace("btn-primary", "btn-outline-primary").replace("btn-secondary", "btn-outline-secondary");
        event.target.className = className.replace("btn-outline-secondary", "btn-secondary").replace("btn-outline-primary", "btn-primary");

        LogService.by_user(id).then(async (logs) => {
            var data = [];
            if (logFilter === 'all') {
                data = await logs.data;
            }
            if (logFilter === 'login') {
                data = await logs.data.filter(function (log) {
                    return log.module === logFilter
                });
            }

            setLogs(data);
            setTableData({columns, data});
        });
    };

    return (
        <Layout>
            <a as={Link}
                href="/users"
                className="btn btn-info mb-2">
                Back
            </a>

            {
            user && <Row className="mt-2">
                <Col md="6">
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="username">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" placeholder="Ex: Testing" name="username"
                                defaultValue={
                                    user.username
                                }/>
                        </Form.Group>

                        <Form.Group controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" placeholder="Ex: user@districtpurchases.com" name="email"
                                defaultValue={
                                    user.email
                                }/>
                        </Form.Group>

                        <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" name="password"
                                defaultValue={
                                    user.password
                                }/>
                        </Form.Group>

                        <Row>
                            <Col md="8" className="ml-2 mt-4">
                                <Form.Group controlId="isAdmin">
                                    <Form.Check type="checkbox" label="Is Admin?"
                                        defaultChecked={
                                            Boolean(user.isAdmin)
                                        }/>
                                </Form.Group>
                            </Col>

                            <Col md="3" className="text-right mt-2">
                                <Button type="submit" variant="success">Save</Button>
                            </Col>
                        </Row>


                    </Form>
                </Col>

                <Col md="6">
                    {
                    logs && tableData && <Row>
                        <Col md="12">

                            <div className="text-right">
                                <Button className="mr-2 ml-2"
                                    onClick={refresh_orders}
                                    variant={"primary"}
                                    id="all">
                                    All
                                </Button>
                                <Button className="mr-2 ml-2"
                                    variant={"outline-secondary"}
                                    id="login"
                                    onClick={refresh_orders}>
                                    Login
                                </Button>
                            </div>

                            <DataTableExtensions {...tableData}
                                filterPlaceholder={"Search logs..."}
                                print={false}>
                                <DataTable className="mt-4"
                                    columns={columns}
                                    data={logs}
                                    pagination
                                    highlightOnHover
                                    subHeader={true}
                                    striped={true}
                                    fixedHeader={true}/>
                            </DataTableExtensions>
                        </Col>

                    </Row>
                } </Col>

                <Col md="12"></Col>

            </Row>
        } </Layout>
    );
};

export default EditUser;
