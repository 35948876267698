import {Badge} from "react-bootstrap";

export const columns = [
    {
        name: "User",
        selector: "username",
        sortable: true,
        cell: (row) => <div data-tag="allowRowEvents">
            <a href={
                `../users/edit/${
                    row.userId
                }`
            }>
                {
                row.username
            } </a>
        </div>
    },
    {
        name: "Action",
        selector: "action",
        sortable: true,
        cell: (row) => <Badge variant={

            row.action === 'POST' ? 'success' : row.action === 'PUT' ? 'warning' : 'danger'
        }>
            {
            row.action.replace('POST', 'ADD').replace('PUT', 'EDIT').replace('DELETE', 'DELETE')
        }</Badge>
    },
    {
        name: "Module",
        selector: "module",
        sortable: true
    },
    {
        name: "Reference Id",
        selector: "reference_id",
        sortable: true,
        cell: (row) => <div data-tag="allowRowEvents">
            <a href={
                `../../${
                    row.module
                }/${
                    row.action.replace('POST', 'edit').replace('PUT', 'edit').replace('DELETE', 'edit')
                }/${
                    row.reference_id
                }`
            }>
                {
                row.reference_id
            }</a>
        </div>
    }, {
        name: "Date & Time",
        selector: "createdAt",
        sortable: true,
        cell: (row) => <div data-tag="allowRowEvents">
            <p> {
                new Date(row.createdAt).toLocaleDateString()
            }
                -{
                new Date(row.createdAt).toLocaleTimeString()
            } </p>
        </div>
    },
];
