export const CATEGORIES = [
  "Disposable Masks",
  "T-Shirts",
  "Hoodies",
  "Gaiter",
  "Cloth Masks",
  "Tote Bags",
  "Favor bags",
  "Hats",
  "Kraft Bags",
  "Windbreakers",
  "Coolers",
  "Boxes"
];
