import Layout from '../components/Layout';
import { useSelector } from 'react-redux';
import ProductService from '../api/product.service';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { columns } from '../table-columns/products';
import { Button, Modal, Form } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import loading from '../img/loading.gif';

const customStyles = {
  rows: {
    style: {
      minHeight: '72px', // override the row height
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px', // override the cell padding for head cells
      paddingRight: '8px',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px', // override the cell padding for data cells
      paddingRight: '8px',
    },
  },
};

const Products = () => {
  const file_extensions = ['application/vnd.ms-excel', 'text/csv'];

  const history = useHistory();
  const { username } = useSelector((state) => state.loggedInUser);
  const [tableData, setTableData] = useState(null);
  const [data, setData] = useState(null);
  const [rows, setRows] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    ProductService.all().then(async (data) => {
      setData(data);
      setTableData({ columns, data });
    });
  }, []);

  const handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    setRows(state.selectedRows);
  };

  const loadProduct = (state) => {
    history.push('/products/edit/' + state._id);
  };

  const deleteRows = () => {
    swal({
      title: 'Are you sure?',
      text: rows.length + ' products are going to be deleted.',
      icon: 'error',
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        rows.forEach(async (product) => {
          await ProductService.delete(product._id).then(() => {
            toast.success('Product deleted successfully!', {
              position: 'top-center',
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          });
        });
        ProductService.all().then((data) => {
          setData(data);
          setTableData({ columns, data });
        });
      }
    });
  };

  const uploadProductFile = async (event) => {
    event.preventDefault();

    if (event.target[0].files.length <= 0) {
      toast.error('No file selected.', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!file_extensions.includes(event.target[0].files[0].type)) {
      toast.warning('Please upload a .csv file.', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    toast.warning('Please wait while we upload your file...', {
      position: 'top-center',
    });

    // send file via API

    await ProductService.bulk({
      file: event.target[0].files[0],
    })
      .then(() => {
        toast.dismiss();
        setShow(false);
        toast.success('Products uploaded successfully!', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((e) => {
        toast.error(
          e.response.data === undefined
            ? e.response
            : e.response.data
            ? ''
            : e.response.data.message,
          {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          }
        );
      });
  };

  return (
    <Layout>
      {' '}
      {username && (
        <div>
          <div className="mb-4">
            <Button
              as={Link}
              to="/products/add"
              className="mr-2 ml-2"
              variant={'primary'}
            >
              Add
            </Button>
            <Button
              className="mr-2 ml-2"
              variant={'success'}
              onClick={handleShow}
            >
              Bulk
            </Button>
            <Button
              disabled={rows.length === 0}
              className="mr-2 ml-2"
              variant={'danger'}
              onClick={deleteRows}
            >
              Delete
            </Button>
          </div>
          <div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header className="bg-success text-white" closeButton>
                <Modal.Title>Upload your .csv file</Modal.Title>
              </Modal.Header>
              <Form onSubmit={(e) => uploadProductFile(e)}>
                <Modal.Body>
                  <p>
                    Please check the following instructions for uploading bulk
                    product information:
                  </p>
                  <ul>
                    <li style={{ listStyle: 'circle' }}>
                      You can download the Excel template{' '}
                      <a href="../files/bulk_product.xlsx" download>
                        here
                      </a>
                      .
                    </li>
                    <li style={{ listStyle: 'circle' }}>
                      Please navigate between the template tabs to look for the
                      acceptable values per column.
                    </li>
                    <li style={{ listStyle: 'circle' }}>
                      Make sure to upload a <b>.csv</b> file.
                    </li>
                    <li style={{ listStyle: 'circle' }}>
                      If you have more than one SKU to be added in the current
                      row, separate them by underscore (_). Ex: sku1_sku2_sku3
                      etc.
                    </li>
                  </ul>
                  <Form.Group controlId="formFile" className="mb-3">
                    <Form.Label>
                      Drag or select your <b>.csv</b> file.
                    </Form.Label>
                    <Form.Control type="file" />
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                  <Button variant="success" type="submit">
                    Upload
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </div>
          {!data && (
            <div className="text-center">
              <img src={loading} alt={'loading'} />
            </div>
          )}
          {data && tableData && (
            <DataTableExtensions
              {...tableData}
              filterPlaceholder={'Search Products'}
              print={false}
            >
              <DataTable
                className="mt-4"
                columns={columns}
                data={data}
                pagination
                highlightOnHover
                customStyles={customStyles}
                selectableRows
                onSelectedRowsChange={handleChange}
                onRowClicked={loadProduct}
                subHeader={true}
                striped={true}
                fixedHeader={true}
              />
            </DataTableExtensions>
          )}{' '}
        </div>
      )}{' '}
    </Layout>
  );
};

export default Products;
