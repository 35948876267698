import Layout from '../components/Layout';
import { useSelector } from 'react-redux';
import OrderService from '../api/order.service';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { columns } from '../table-columns/orders';
import {
  Button,
  Row,
  Col,
  DropdownButton,
  Dropdown,
  ButtonGroup,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import { toast } from 'react-toastify';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import '../style/other.css';
import loading from '../img/loading.gif';
import { AiOutlinePlus, AiOutlineClose, AiOutlineCheck } from 'react-icons/ai';
import { BsTrash } from 'react-icons/bs';
import Switch from "react-switch";

const conditionalRowStyles = [
  {
    when: (row) => new Date(row.ship_date) < new Date(),
    style: {
      backgroundColor: '#ff98a9',
    },
  },
  {
    when: (row) =>
      new Date(row.ship_date).toLocaleDateString() ===
      new Date().toLocaleDateString(),
    style: {
      backgroundColor: '#fff4d5',
    },
  },
];

const customStyles = {
  rows: {
    style: {
      maxHeight: '180px', // override the row height
      minHeight: '80px',
    },
  },
  headCells: {
    style: {
      paddingLeft: '65px', // override the cell padding for head cells
      paddingRight: '5px',
      textAlign: 'center',
    },
  },
  cells: {
    style: {
      textAlign: 'center',
      maxHeight: '180px', // override the row height
    },
  },
};

const Orders = () => {
  const { username } = useSelector((state) => state.loggedInUser);

  const [data, setData] = useState(null);
  const [rows, setRows] = useState([]);
  const [status, setStatus] = useState('added');
  const [checked, setChecked] = useState(false);
  const [tableData, setTableData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(false);
        setData([]);
  }, []);

  const cancelOrders = async () => {
    swal({
      title: 'Are you sure?',
      text: rows.length + ' orders are going to be canceled.',
      icon: 'warning',
      buttons: true,
      dangerMode: false,
    }).then(async (willDelete) => {
      if (willDelete) {
        rows.forEach(async (row) => {
          await OrderService.cancel({ id: row._id }).then(async () => {
            toast.warning('Order(s) canceled successfully!', {
              position: 'top-center',
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          });
          OrderService.all().then(async (orders) => {
            var data = await orders.filter(function (order) {
              return status === order.status;
            });
            setData(data);
            setTableData({ columns, data });
            setRows([]);
          });
        });
      }
    });
  };

  const showHideOrders = async (value) => {
    setChecked(value);
    if (value) {
      setIsLoading(true);
      OrderService.all().then(async (orders) => {
        var data = await orders.filter(function (order) {
          return status === order.status;
        });
        setIsLoading(false);
        setData(data);
        setTableData({ columns, data });
      });
    }
    else {
      setIsLoading(false);
      setData([]);
      setTableData(null);
    }
  };

  const completeOrders = async () => {
    swal({
      title: 'Are you sure?',
      text: rows.length + ' orders are going to be completed.',
      icon: 'warning',
      buttons: true,
      dangerMode: false,
    }).then(async (willComplete) => {
      if (willComplete) {
        rows.forEach(async (row) => {
          await OrderService.complete({ id: row._id }).then(async () => {
            toast.success('Order(s) completed successfully!', {
              position: 'top-center',
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          });
          OrderService.all().then(async (orders) => {
            var data = await orders.filter(function (order) {
              return status === order.status;
            });
            setData(data);
            setTableData({ columns, data });
            setRows([]);
          });
        });
      }
    });
  };

  const deleteOrders = async () => {
    swal({
      title: 'Are you sure?',
      text: rows.length + ' orders are going to be deleted.',
      icon: 'warning',
      buttons: true,
      dangerMode: false,
    }).then(async (willDelete) => {
      if (willDelete) {
        rows.forEach(async (row) => {
          await OrderService.delete({ id: row._id }).then(async () => {
            toast.success('Order(s) deleted successfully!', {
              position: 'top-center',
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
            });
          });
          OrderService.all().then(async (orders) => {
            var data = await orders.filter(function (order) {
              return status === order.status;
            });
            setData(data);
            setTableData({ columns, data });
            setRows([]);
          });
        });
      }
    });
  };

  const refresh_orders = async (e) => {
    setIsLoading(true);
    setStatus(null);
    e.preventDefault();
    var stat = e.target.id;
    var className = e.target.className;
    setStatus(stat);
    var current = document.getElementById(status).className;
    document.getElementById(status).className = current
      .replace('btn-warning', 'btn-outline-warning')
      .replace('btn-success', 'btn-outline-success')
      .replace('btn-primary', 'btn-outline-primary');
    e.target.className = className
      .replace('btn-outline-warning', 'btn-warning')
      .replace('btn-outline-success', 'btn-success')
      .replace('btn-outline-primary', 'btn-primary');
    OrderService.get_by_status({status: stat}).then(async (data) => {
      setIsLoading(false);
      setData(data);
      setTableData({ columns, data });
    });
  };

  const handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    setRows(state.selectedRows);
  };

  return (
    <Layout>
      {' '}
      {username && (
        <div className="row">
          <div className="col-md-12 mb-4">
            {isLoading && (
              <div className="text-center">
                <img src={loading} alt={'loading'} />
              </div>
            )}
            {data && !isLoading && (
              <Row
                style={{
                  marginBottom: 50 + 'px',
                }}
              >
                <div className="col-md-12">
                  <Row>
                    <Col>
                      <Button
                        as={Link}
                        to="/orders/add"
                        className="mr-2 ml-2"
                        variant={'primary'}
                      >
                        <AiOutlinePlus />
                      </Button>
                      <Button
                        className="mr-2 ml-2"
                        variant={'warning'}
                        disabled={rows.length === 0}
                        onClick={cancelOrders}
                      >
                        <AiOutlineClose />
                      </Button>
                      <Button
                        className="mr-2 ml-2"
                        variant={'success'}
                        disabled={rows.length === 0}
                        onClick={completeOrders}
                      >
                        <AiOutlineCheck />
                      </Button>
                      <Button
                        className="mr-2 ml-2"
                        variant={'danger'}
                        disabled={rows.length === 0}
                        onClick={deleteOrders}
                      >
                        <BsTrash />
                      </Button>

                      <label>
                        <span className="mr-2">Search</span>
                        <Switch onChange={showHideOrders} checked={checked} />
                        <span className="ml-2">View Orders</span>
                      </label>

                      <div style={{ float: 'right' }}>
                        <DropdownButton
                          as={ButtonGroup}
                          title="Status"
                          bsPrefix="btn btn-secondary"
                        >
                          <Dropdown.Item
                            eventKey="1"
                            onClick={refresh_orders}
                            id="added"
                          >
                            Open
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="2"
                            id="canceled"
                            onClick={refresh_orders}
                          >
                            Canceled
                          </Dropdown.Item>
                          <Dropdown.Item
                            eventKey="3"
                            id="completed"
                            onClick={refresh_orders}
                          >
                            Completed
                          </Dropdown.Item>
                        </DropdownButton>
                      </div>

                      <Row>
                        <Col>
                          <div id="orders">
                            <DataTableExtensions
                              className="mt-4 mb-1"
                              {...tableData}
                              filterPlaceholder={'Search Orders'}
                              print={false}
                              export={false}
                            >
                              <DataTable
                                pagination={false}
                                selectableRows
                                onSelectedRowsChange={handleChange}
                                customStyles={customStyles}
                                subHeader={true}
                                responsive={true}
                                style={{ maxWidth: '100%' }}
                                striped={true}
                                fixedHeader={true}
                                conditionalRowStyles={conditionalRowStyles}
                              />
                            </DataTableExtensions>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Row>
            )}{' '}
          </div>
        </div>
      )}{' '}
    </Layout>
  );
};

export default Orders;
