export const columns = [
  {
    name: "",
    cell: (row) => row.picture_url !== undefined && row.picture_url !== '' ? <img src={row.picture_url} alt={row.picture_url} style={{ maxHeight: '72px' }} /> : ''
  },
  {
    name: "Name",
    selector: "name",
    sortable: true,
  },
  {
    name: "Product Code",
    selector: "code",
    sortable: true,
  },
  {
    name: "Color",
    selector: "color",
    sortable: true,
    cell: (row) => (
      <span
        style={{
          fontWeight: "bold",
        }}
      >
        {row.color}
      </span>
    ),
  },
  {
    name: "Size",
    selector: "size",
    sortable: true,
  },
  {
    name: "Category",
    selector: "listing_category",
    sortable: true,
  },
  {
    name: "Created At",
    selector: "createdAt",
    sortable: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {new Date(row.createdAt).toLocaleDateString()}
      </div>
    ),
  },
];
