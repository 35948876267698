import Layout from "../../components/Layout";
import React from "react";
import {Button, Form, Row, Col} from "react-bootstrap";
import UserService from "../../api/user.service";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import "react-data-table-component-extensions/dist/index.css";

const AddUser = () => {

    const handleSubmit = async (event) => {

        event.preventDefault();

        await UserService.add({username: event.target.username.value, email: event.target.email.value, password: event.target.password.value, isAdmin: event.target.isAdmin.checked}).then(() => {
            toast.dismiss();
            toast.success("User created successfully!", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined
            });
        }).catch((e) => {
            toast.error(e.response.user === undefined ? e.response : e.response.user.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined
            });
        });
    }

    return (
        <Layout>
            <a as={Link}
                href="/users"
                className="btn btn-info mb-2">
                Back
            </a>

            <Row className="mt-2">
                <Col md="6">
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="username">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" placeholder="Ex: Testing" name="username"/>
                        </Form.Group>

                        <Form.Group controlId="email">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" placeholder="Ex: user@districtpurchases.com" name="email"/>
                        </Form.Group>

                        <Form.Group controlId="password">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" name="password"/>
                        </Form.Group>

                        <Row>
                            <Col md="8" className="ml-2 mt-4">
                                <Form.Group controlId="isAdmin">
                                    <Form.Check type="checkbox" label="Is Admin?"/>
                                </Form.Group>
                            </Col>

                            <Col md="3" className="text-right mt-2">
                                <Button type="submit" variant="success">Save</Button>
                            </Col>
                        </Row>


                    </Form>
                </Col>

            </Row>
        </Layout>
    );
};

export default AddUser;
