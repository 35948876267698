import Layout from "../../components/Layout";
import React, {useState, useEffect} from "react";
import {Button, Form, Alert} from "react-bootstrap";
import Select from "react-select";
import ProductService from "../../api/product.service";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import {COLORS} from "../../constants/colors";
import {SIZES} from "../../constants/sizes";
import {SOURCES} from "../../constants/sources";
import {CATEGORIES} from "../../constants/listing_categories";

const AddProducts = () => {
    const [errors, setErrors] = useState(null);
    const [sizes, setSizes] = useState(null);
    const [colors, setColors] = useState(null);
    const [color, setColor] = useState(null);
    const [size, setSize] = useState(null);
    const [sources, setSources] = useState(null);
    const [source, setSource] = useState(null);
    const [categories, setCategories] = useState(null);
    const [category, setCategory] = useState(null);

    const handleListingCategoryChange = (selectedOption) => {
        setCategory(selectedOption.value);
    };

    const handleColorChange = (selectedOption) => {
        setColor(selectedOption.value);
    };

    const handleSizeChange = (selectedOption) => {
        setSize(selectedOption.value);
    };

    const handleListingCodeChange = (selectedOption) => {
        setSource(selectedOption.value);
    };

    useEffect(() => {
        setColors(COLORS.map((color, index) => ({label: color, value: color})));
        setSizes(SIZES.map((size, index) => ({label: size, value: size})));
        setSources(SOURCES.map((source, index) => ({label: source, value: source})));
        setCategories(CATEGORIES.map((category, index) => ({label: category, value: category})));
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            await ProductService.add({
                name: event.target.name.value,
                model_number: event.target.model_number.value,
                code: event.target.code.value,
                color: color,
                size: size,
                source: source,
                listing_code: event.target.listing_code.value,
                listing_category: category,
                picture_url: event.target.picture_url.value
            }).then(() => {
                toast.success("Product added successfully!", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined
                });
                event.target.reset();
            });
        } catch (e) {
            console.error(e);
            setErrors([
                e.response.data === undefined ? e.response : e.response.data.message,
            ]);
        }
    };

    return (
        <Layout>
            <a as={Link}
                href="/products"
                className="btn btn-info mb-4">
                Back
            </a>

            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" placeholder="Ex: Contoured Mask" name="name"/>
                </Form.Group>
                <Form.Group controlId="code">
                    <Form.Label>Internal Code</Form.Label>
                    <Form.Control type="text" placeholder="Ex: Hyfe0001"/>
                </Form.Group>
                <Form.Group controlId="model_number">
                    <Form.Label>Model Number</Form.Label>
                    <Form.Control type="text" placeholder="Ex: Hyfe0001"/>
                </Form.Group>
                <Form.Group controlId="color">
                    <Form.Label>Color</Form.Label>
                    <Select options={colors}
                        onChange={handleColorChange}/>
                </Form.Group>
                <Form.Group controlId="size">
                    <Form.Label>Size</Form.Label>
                    <Select options={sizes}
                        onChange={handleSizeChange}/>
                </Form.Group>
                <Form.Group controlId="source">
                    <Form.Label>Source</Form.Label>
                    <Select options={sources}
                        onChange={handleListingCodeChange}/>
                </Form.Group>
                <Form.Group controlId="listing_category">
                    <Form.Label>Listing Category</Form.Label>
                    <Select options={categories}
                        onChange={handleListingCategoryChange}/>
                </Form.Group>
                <Form.Group controlId="listing_code">
                    <Form.Label>Listing Code</Form.Label>
                    <Form.Control type="text" placeholder="Ex: https://myproduct.com/123"/>
                </Form.Group>
                <Form.Group controlId="picture_url">
                    <Form.Label>Picture Image</Form.Label>
                    <Form.Control type="text" placeholder="Ex: https://myproduct.com/123"/>
                </Form.Group>
                <Button variant="success" type="submit">
                    Save
                </Button>

                <div className="mt-3">
                    {
                    errors && errors.map((error, index) => (
                        <Alert variant="danger"
                            key={index}>
                            {error} </Alert>
                    ))
                }
                    {" "} </div>
            </Form>
        </Layout>
    );
};

export default AddProducts;
