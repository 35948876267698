import Layout from "../components/Layout";
import React, { useEffect, useState } from "react";
import LogService from "../api/log.service";
import { columns } from "../table-columns/all_logs";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import loading from "../img/loading.gif";

const Logs = () => {
    const [data, setData] = useState(null);
    const [tableData, setTableData] = useState(null);

    useEffect(() => {
        LogService.all().then(async (logs) => {
            var data = await logs.filter(function (log) {
                return log.module !== 'login'
            });
            setData(data);
            setTableData({ columns, data });
        });
    }, []);

    return (
        <Layout>
            {!data && (
                <div className="text-center">
                    <img src={loading} alt={'loading'} />
                </div>
            )
            }

            {
                data && tableData && (

                    <DataTableExtensions {...tableData}
                        filterPlaceholder={"Search logs..."}
                        print={false}>
                        <DataTable className="mt-4"
                            columns={columns}
                            data={data}
                            pagination
                            striped={true}
                            fixedHeader={true} />
                    </DataTableExtensions>
                )
            } </Layout>
    );
};

export default Logs;
