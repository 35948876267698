import Layout from "../components/Layout";
import { Button, Form, Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import OrderService from "../api/order.service";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { toast } from "react-toastify";
import { FiDownload } from "react-icons/fi";
import loading from "../img/loading.gif";
import "../style/approved_proofs.css";

const ApprovedProofs = () => {
    const [columns, setColumns] = useState([
        {
            name: "Proof",
            sortable: true,
            cell: (row) => (
                <span className="text-primary">
                    <img src={
                        row.proof_approved_url
                    }
                        style={
                            {
                                width: "auto",
                                maxHeight: "90px"
                            }
                        }
                        alt={
                            row.proof_approved_url
                        } />
                </span>
            )
        },
        {
            cell: (row) => (
                <span className="text-primary">
                    <a className="btn btn-success btn-sm"
                        href={
                            row.proof_approved_url
                        }>
                        <FiDownload />
                    </a>
                </span>
            )
        },
        {
            name: "Customer Files",
            cell: (row) => row.customer_files.length === 0 ? "No files uploaded." : row.customer_files.map(
                (file, index) => {
                    return (
                        <span className="text-primary mr-1 ml-1">
                            <a className="btn btn-success btn-sm"
                                href={
                                    file.file_url
                                }>
                                <FiDownload />
                            </a>
                        </span>
                    );
                }
            )
        },
        {
            name: "Approved Date",
            selector: "date_proof_approved",
            sortable: true,
            cell: (row) => (
                <span>{
                    new Date(row.date_proof_approved).toLocaleDateString()
                }</span>
            )
        }, {
            name: "Order #",
            selector: "number",
            sortable: true
        }, {
            name: "Customer Name",
            selector: "customer_name",
            sortable: true
        }, {
            name: "Action",
            cell: (row) => (
                <Button className="btn-sm"
                    variant={"primary"}
                    id={
                        row._id
                    }
                    onClick={handleShow}>
                    Upload
                </Button>
            )
        },
    ]);

    const customStyles = {
        rows: {
            style: {
                maxHeight: "180px", // override the row height
            }
        },
        headCells: {
            style: {
                paddingLeft: "12px", // override the cell padding for head cells
                paddingRight: "12px",
                textAlign: "center"
            }
        },
        cells: {
            style: {
                textAlign: "center",
                maxHeight: "180px", // override the row height
            }
        }
    };

    const [isLoading, setIsLoading] = useState(true);
    const [orderId, setOrderId] = useState(null);
    const [data, setData] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [status, setStatus] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = (event) => {
        setOrderId(event.target.id);
        setShow(true);
    };

    useEffect(() => {
        OrderService.all().then(async (orders) => {
            var data = await orders.filter(function (order) {
                return order.proof_approved && (order.artwork_uploaded === status);
            });
            setIsLoading(false);
            setData(data);
            setColumns(columns);
            setTableData({ columns, data });
        });
    }, [columns, status]);

    const refresh_data = async (e) => {
        setStatus(e.target.checked);
        setIsLoading(true);

        OrderService.all().then(async (orders) => {
            var data = await orders.filter(function (order) {
                return order.proof_approved && (order.artwork_uploaded === status);
            });
            setIsLoading(false);
            setData(data);
            setColumns(columns);
            setTableData({ columns, data });
        });
    };

    const uploadFile = async (event) => {
        event.preventDefault();

        if (event.target[0].files.length <= 0) {
            toast.error("No file selected.", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined
            });
            return;
        }

        toast.warning("Please wait while we upload your file...", {
            position: "top-center",
            draggable: true,
            progress: undefined
        });

        await OrderService.upload({ order_id: orderId, file: event.target[0].files[0], type: "artwork" }).then(() => {
            toast.dismiss();
            setShow(false);
            toast.success("Artwork uploaded successfully!", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined
            });
            OrderService.all().then(async (orders) => {
                var data = await orders.filter(function (order) {
                    return order.proof_approved_url !== "" && order.artwork_url === "";
                });
                setData(data);
                setColumns(columns);
                setTableData({ columns, data });
            });
        });
    };

    return (
        <Layout> {" "}

            {isLoading && (
                <div className="text-center">
                    <img src={loading} alt={'loading'} />
                </div>
            )
            }

            {
                data && !isLoading && (
                    <div>
                        <div className="mb-4 text-right">
                            <Form.Group controlId="uploadedFilter">
                                <Form.Check type="checkbox" label="Uploaded?"
                                    checked={status}
                                    onChange={refresh_data} />
                            </Form.Group>
                        </div>

                        <div id="approved_proofs">
                            <DataTableExtensions {...tableData}
                                filterPlaceholder={"Search Orders"}
                                print={false}>
                                <DataTable className="mt-4"
                                    columns={columns}
                                    data={data}
                                    pagination
                                    striped={true}
                                    subHeader={true}
                                    fixedHeader={true}
                                    customStyles={customStyles} />
                            </DataTableExtensions>
                        </div>


                        <Modal show={show}
                            onHide={handleClose}>
                            <Modal.Header className="bg-primary text-white" closeButton>
                                <Modal.Title>Artwork Upload</Modal.Title>
                            </Modal.Header>
                            <Form onSubmit={uploadFile}>
                                <Modal.Body>
                                    <Form.Group>
                                        <Form.File id="file" name="file" label="Upload the artwork by choosing your file below:" />
                                    </Form.Group>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button type="button" variant="secondary"
                                        onClick={handleClose}>
                                        Close
                                </Button>
                                    <Button type="submit" variant="primary">
                                        Upload
                                </Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    </div>
                )
            }
            {" "} </Layout>
    );
};

export default ApprovedProofs;
