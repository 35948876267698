import Layout from "../components/Layout";
import { Button, Form, Row, Col } from "react-bootstrap";
import React, { useState } from "react";
import OrderService from "../api/order.service";
import { columns } from '../table-columns/orders';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';

const conditionalRowStyles = [
    {
        when: (row) => new Date(row.ship_date) < new Date(),
        style: {
            backgroundColor: '#ff98a9',
        },
    },
    {
        when: (row) =>
            new Date(row.ship_date).toLocaleDateString() ===
            new Date().toLocaleDateString(),
        style: {
            backgroundColor: '#fff4d5',
        },
    },
];

const customStyles = {
    rows: {
        style: {
            maxHeight: '180px', // override the row height
            minHeight: '80px',
        },
    },
    headCells: {
        style: {
            paddingLeft: '65px', // override the cell padding for head cells
            paddingRight: '5px',
            textAlign: 'center',
        },
    },
    cells: {
        style: {
            textAlign: 'center',
            maxHeight: '180px', // override the row height
        },
    },
};


const Search = () => {
    const [tableData, setTableData] = useState(null);
    const [, setRows] = useState([]);

    const handleChange = (state) => {
        // You can use setState or dispatch with something like Redux so we can use the retrieved data
        setRows(state.selectedRows);
    };

    const searchOrders = async (event) => {

        event.preventDefault();

        setTableData(null);

        try {
            var obj = {};

            if (event.target.number.value) {
                obj.number = event.target.number.value;
            }

            if (event.target.first_name.value) {
                obj.first_name = event.target.first_name.value;
            }

            if (event.target.last_name.value) {
                obj.last_name = event.target.last_name.value;
            }

            if (Object.keys(obj).length === 0) {
                throw Object.assign(
                    new Error("Please insert one criteria to search"),
                    { code: 402 }
                );
            }

            OrderService.search({ params: obj }).then((data) => {
                setTableData({ columns, data });
            });
        }
        catch (e) {
            toast.error(e.message, {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
            });
        }
    }

    return (
        <Layout>
            <Form onSubmit={searchOrders}>
                <Row>
                    <Col md="2">
                        <Form.Group controlId="number">
                            <Form.Label>Order Number</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                    </Col>
                    <Col md="2">
                        <Form.Group controlId="first_name">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                    </Col>
                    <Col md="2">
                        <Form.Group controlId="last_name">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text" />
                        </Form.Group>
                    </Col>

                    <Col md="3">
                        <Button className="mt-4 mb-4" variant={"success"} type="submit">
                            Search
                        </Button>
                    </Col>
                </Row>
            </Form>

            {tableData &&

                <Row>
                    <Col>
                        <div id="orders">
                            <DataTableExtensions
                                className="mt-4 mb-1"
                                {...tableData}
                                filterPlaceholder={'Search Orders'}
                                print={false}
                                export={false}
                            >
                                <DataTable
                                    // data={data}
                                    // columns={columns}
                                    pagination={false}
                                    selectableRows
                                    onSelectedRowsChange={handleChange}
                                    customStyles={customStyles}
                                    subHeader={true}
                                    responsive={true}
                                    style={{ maxWidth: '100%' }}
                                    striped={true}
                                    fixedHeader={true}
                                    conditionalRowStyles={conditionalRowStyles}
                                />
                            </DataTableExtensions>
                        </div>
                    </Col>
                </Row>

            }{" "}
        </Layout>
    );
};

export default Search;
