import Layout from '../components/Layout';
import { useSelector } from 'react-redux';
import OrderService from '../api/order.service';
import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { columns } from '../table-columns/orders';
import {
  Row,
  Col,
} from 'react-bootstrap';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';
import '../style/other.css';
import loading from '../img/loading.gif';

const conditionalRowStyles = [
  {
    when: (row) => new Date(row.ship_date) < new Date(),
    style: {
      backgroundColor: '#ff98a9',
    },
  },
  {
    when: (row) =>
      new Date(row.ship_date).toLocaleDateString() ===
      new Date().toLocaleDateString(),
    style: {
      backgroundColor: '#fff4d5',
    },
  },
];

const customStyles = {
  rows: {
    style: {
      maxHeight: '180px', // override the row height
      minHeight: '80px',
    },
  },
  headCells: {
    style: {
      paddingLeft: '65px', // override the cell padding for head cells
      paddingRight: '5px',
      textAlign: 'center',
    },
  },
  cells: {
    style: {
      textAlign: 'center',
      maxHeight: '180px', // override the row height
    },
  },
};

const ReadyToEnter = () => {
  const { username } = useSelector((state) => state.loggedInUser);

  const [data, setData] = useState(null);
  const [, setRows] = useState([]);
  const [tableData, setTableData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    OrderService.ready_to_enter().then(async (data) => {
      setIsLoading(false);
      setData(data);
      setTableData({ columns, data });
      setIsLoading(false);
    });
  }, []);

  const handleChange = (state) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    setRows(state.selectedRows);
  };

  return (
    <Layout>
      {' '}
      {username && (
        <div className="row">
          <div className="col-md-12 mb-4">
            {isLoading && (
              <div className="text-center">
                <img src={loading} alt={'loading'} />
              </div>
            )}
            {data && !isLoading && (
              <Row
                style={{
                  marginBottom: 50 + 'px',
                }}
              >
                <div className="col-md-12">
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <div id="orders">
                            <DataTableExtensions
                              className="mt-4 mb-1"
                              {...tableData}
                              filterPlaceholder={'Search Orders'}
                              print={false}
                              export={false}
                            >
                              <DataTable
                                // data={data}
                                // columns={columns}
                                pagination={false}
                                selectableRows
                                onSelectedRowsChange={handleChange}
                                customStyles={customStyles}
                                subHeader={true}
                                responsive={true}
                                style={{ maxWidth: '100%' }}
                                striped={true}
                                fixedHeader={true}
                                conditionalRowStyles={conditionalRowStyles}
                              />
                            </DataTableExtensions>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Row>
            )}{' '}
          </div>
        </div>
      )}{' '}
    </Layout>
  );
};

export default ReadyToEnter;
