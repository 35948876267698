export const COLORS = [
  "White",
  "Black",
  "Antique Cherry Red",
  "Antique Irish Green",
  "Antique Jade Dome",
  "Antique Orange",
  "Antique Sapphire",
  "Ash",
  "Azalea",
  "Berry",
  "Blackberry",
  "Brown Savana",
  "Cardinal",
  "Carolina Blue",
  "Charcoal",
  "Chocolate",
  "Cobalt",
  "Coral Silk",
  "Cornsilk",
  "Daisy",
  "Dark Chocolate",
  "Dark Heather",
  "Electric Green",
  "Forest Green",
  "Garnet",
  "Gold",
  "Graphite Heather",
  "Gravel",
  "Grey",
  "Heather Military Green",
  "Heather Navy",
  "Heather Radiant Orchid",
  "Heather Red",
  "Heather Sapphire",
  "Heliconia",
  "Hot Pink",
  "Ice Grey",
  "Indigo Blue",
  "Irish Green",
  "Kiwi",
  "Lavender",
  "Light Blue",
  "Light Pink",
  "Lilac",
  "Lime",
  "Maroon",
  "Midnight",
  "Military Green",
  "Mint Green",
  "Natural",
  "Navy",
  "Neon Blue",
  "Neon Green",
  "Old Gold",
  "Orange",
  "Purple",
  "Red",
  "Royal",
  "Russet",
  "Safety Green",
  "Safety Orange",
  "Safety Pink",
  "Sand",
  "Sapphire",
  "Sky",
  "Sport Grey",
  "Sunset",
  "Tennessee Orange",
  "Texas Orange",
  "Tropical Blue",
  "Turf Green",
  "Turquoise",
  "Tweed",
  "Violet",
  "Yellow Haze",
];
