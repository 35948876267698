import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  line_items: [],
};

const orderItems = createSlice({
  name: "orderItems",
  initialState,
  reducers: {
    addItem(state, action) {
      state.line_items.push(action.payload);
    },
    removeItem(state, action) {
      var index = state.line_items.findIndex(
        (x) => x._id === action.payload._id
      );
      if (index !== -1) {
        state.line_items.splice(index, 1);
      }
    },
    setItems(state, action) {
      state.line_items = action.payload;
    },
    destroyLineItems(state, action) {
      state.line_items = [];
    },
  },
});

export const {
  addItem,
  removeItem,
  destroyLineItems,
  setItems,
} = orderItems.actions;

export default orderItems.reducer;
