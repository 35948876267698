import { authedAxiosInstance } from "./api.service";

const NoteService = {
  all: async () => {
    const response = await authedAxiosInstance.get("/notes");
    return response.data.data;
  },
  add: async ({ content }) => {
    const response = await authedAxiosInstance.post(`/notes/add`, { content });
    return response.data;
  },
  delete: async (id) => {
    const response = await authedAxiosInstance.delete(`/notes/${id}`);
    return response.data;
  },
};

export default NoteService;
