import OrderService from '../api/order.service';
import { toast } from 'react-toastify';
import { RiCheckboxBlankCircleFill } from 'react-icons/ri';

async function handleRow(name, value, id) {
  var order = {
    id: id,
  };
  order[name] = !value;
  await OrderService.update(order).then(() => {
    toast.success('Order updated successfully!', {
      position: 'top-center',
      autoClose: 600,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
    setTimeout(() => {
      window.location.reload();
    }, 500);
  });
}

async function send(link) {
  window.location.replace(link);
}

export const columns = [
  {
    name: 'Customer / Order',
    selector: 'number',
    sortable: true,
    center: true,
    cell: (row) => (
      <div
        data-tag="allowRowEvents"
        style={{
          fontSize: 10 + 'pt',
          paddingTop: 2,
          paddingBottom: 2,
        }}
      >
        {row.customer_name === ''
          ? row.first_name + ' ' + row.last_name
          : row.customer_name}
        <br />
        <br />
        <b
          onClick={(e) => send(`/orders/edit/${row._id}`)}
          style={{ cursor: 'pointer' }}
        >
          {row.number}/{row.system_number}
        </b>
      </div>
    ),
  },
  {
    name: 'Ship Date',
    selector: 'ship_date',
    sortable: true,
    center: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {new Date(row.ship_date).toLocaleDateString()}{' '}
      </div>
    ),
  },
  {
    name: 'Shipping',
    selector: 'shipping_method',
    sortable: true,
    center: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        {row.shipping_method} <br />
        {row.customer_paid_for_shipping ? (
          <span className="text-success" style={{ fontWeight: 'bolder' }}>
            Paid
          </span>
        ) : (
          <span className="text-danger" style={{ fontWeight: 'bolder' }}>
            Unpaid
          </span>
        )}
      </div>
    ),
  },
  {
    name: 'Product',
    sortable: true,
    center: true,
    cell: (row) => (
      <div
        data-tag="allowRowEvents"
        style={{
          fontSize: 10 + 'pt',
          paddingTop: 2,
          paddingBottom: 2,
        }}
      >
        {row.ordered_items.length === 1 ? (
          <span>
            {' '}
            {row.ordered_items[0].product_name}-
            <span
              style={{
                fontWeight: 'bold',
              }}
            >
              {row.ordered_items[0].product_color}{' '}
            </span>
            - {row.ordered_items[0].product_size}{' '}
          </span>
        ) : (
          <span>(Multiple Items)</span>
        )}{' '}
      </div>
    ),
  },
  {
    name: 'Intro Email',
    selector: 'introduction_email',
    sortable: true,
    center: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <RiCheckboxBlankCircleFill
          style={{ fontSize: '20pt' }}
          className={row.introduction_email ? 'text-success' : 'text-danger'}
          onClick={(e) =>
            handleRow('introduction_email', row.introduction_email, row._id)
          }
        />
      </div>
    ),
  },
  {
    name: 'Sent Proof',
    selector: 'proof_sent',
    sortable: true,
    center: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <RiCheckboxBlankCircleFill
          style={{ fontSize: '20pt' }}
          className={row.proof_sent ? 'text-success' : 'text-danger'}
          onClick={(e) => handleRow('proof_sent', row.proof_sent, row._id)}
        />
      </div>
    ),
  },
  {
    name: 'Proof Aprvd / Uploaded',
    selector: 'uploaded_proof',
    sortable: true,
    center: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <RiCheckboxBlankCircleFill
          style={{ fontSize: '20pt' }}
          className={row.uploaded_proof ? 'text-success' : 'text-danger'}
          onClick={(e) =>
            handleRow('uploaded_proof', row.uploaded_proof, row._id)
          }
        />
      </div>
    ),
  },
  {
    name: 'Entered Order',
    selector: 'uploaded_proof',
    sortable: true,
    center: true,
    cell: (row) => (
      <div data-tag="allowRowEvents">
        <RiCheckboxBlankCircleFill
          style={{ fontSize: '20pt' }}
          className={row.entered_in_system ? 'text-success' : 'text-danger'}
          onClick={(e) =>
            handleRow('entered_in_system', row.entered_in_system, row._id)
          }
        />
      </div>
    ),
  },
];
