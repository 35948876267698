export const SIZES = [
    "XXS",
    "S",
    "M",
    "L",
    "XL",
    "XXL",
    "3XL",
    "4XL",
    "5XL",
    "6XL",
    "7XL",
    "One Size",
    "NB",
    "3MO",
    "12MO",
    "18MO",
    "24MO",
    "2T",
    "3T",
    "4T",
    "5T",
    "6T",
    "S/M",
    "M/L",
    "L/XL",
];
